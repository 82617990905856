<template>
	<div class=" bg-white">
		<div class="container mx-auto px-6 py-4 xl:px-14">
			<div class="flex flex-wrap items-center">
				<div class="w-1/6 sm:w-1/4 text-left">
					<router-link to="/"><img src="@/assets/images/logo.png" class="h-10 w-auto h-auto max-h-10"></router-link>
				</div>
				<div class="w-5/6 sm:w-3/4 text-right">										
					<router-link v-if="isLogged" to="/admin/dashboard" class="bg-white border border-orange rounded-full text-orange px-4 py-2 mr-6"><userIco class="w-4 -mt-1 text-orange"/> <span class="hidden sm:inline-block">{{ $t("Dashboard") }}</span></router-link>	
					<button @click="logout" class="bg-orange rounded-full text-white px-4 py-2 mr-2 sm:mr-6">{{ $t("Logout") }}</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import pencilIco from '@/svg/pencil.vue'
import userIco from '@/svg/user.vue'

export default {
	components: {pencilIco,userIco},
    data() {
        return {}
    },
    computed: {
    	isLogged() {
    		return this.$store.state.token
    	}
    },
    methods: {
    	logout() {    		
    		window.location.href = 'https://admin.woodietool.eu/backend/rainlab/user/users'
    	}
    }
}
</script>