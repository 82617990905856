<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Povratne informacije o <br> <span class="text-orange">izvajanju WB</span></h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
        	<div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-20 točkami</h6>
                <p class="text-green-dark">Če je vaš končni rezultat med 0 in 20 točkami, sta splošna raven in natančnost izvajanja zaščite WB v organizaciji še vedno slaba. Obstaja veliko prostora za izboljšanje upravljanja praks nepravilnosti, zlasti glede razpoložljivih kanalov za poročanje o nepravilnostih, razkritja vsebine poročanja in preiskovalnih postopkov. Večjo pozornost je treba nameniti tudi vzpostavljanju zaščite pred povračilnimi ukrepi ali viktimizacijo in varovanju zaupnosti identitete WB. Organizacija bi morala več truda in sredstev nameniti orodju WB, upravljanju in spremljanju razkritij ter ugotavljanju morebitnih potrebnih popravnih ukrepov.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">21-40 točkami</h6>
                <p class="text-green-dark">Če je vaš končni rezultat med 21 in 40 točkami, organizacija na splošno izkaže standardno zavzetost pri izvajanju predpisov o zaščiti WB. Zdi se, da pravila in postopki WB ustrezajo zahtevam; kljub temu pa je treba izboljšati še več področij, zlasti glede tega, kako naj bo notranja organizacija strukturirana tako, da bo spodbujala prijave kršitev in učinkoviteje varovala anonimnost prijavitelja nepravilnosti. Raven ozaveščenosti zaposlenih glede vzpostavljenega sistema poročanja ni povsem ustrezna. Organizacija bi si morala bolj prizadevati za povečanje stopnje zaupanja v sistem zaščite WB in njegove koristi med zaposlenimi. Ukrepi za izboljšanje, ki jih je treba sprejeti, lahko vključujejo dodelitev znanj in virov ter odstranitev morebitnih dejavnikov, ki bi lahko ovirali razkritja.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">41-70 točkami</h6>
                <p class="text-green-dark">Če je vaš končni rezultat med 41 in 70 točkami, se zdi, da je organizacija pozitivno ukrepala pri izvajanju zaščite WB. Pravila in postopki se pravilno uporabljajo. Pozornost je namenjena ključnim stebrom sistema, to je delovanju kanalov poročanja, pomembnosti ozaveščevalnih kampanj in usposabljanju zaposlenih in menedžerjev. Organizacija bi lahko sprejela dodatne ukrepe in dosegla nadaljnji napredek s sprejetjem dodatnih prostovoljnih ukrepov (tj. poleg obveznih ukrepov) za povečanje stopnje zaupanja med zaposlenimi in za interno oceno učinkovitosti sistema zaščite WB.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 točkami</h6>
                <p class="text-green-dark">Če je vaš končni rezultat med 71 in 100 točkami, to pomeni, da organizacija uspešno izvaja sistem zaščite WB. Vsi izvedbeni ukrepi so ustrezno vzpostavljeni. Na voljo so različni kanali poročanja in organizacija redno preverja njihovo stopnjo učinkovitosti. Organizacija zagotavlja tudi vse potrebne komunikacijske in izobraževalne dejavnosti. Poleg tega si je že prizadevala za okrepitev notranjega upravljanja zaščitnega sistema WB in za izboljšanje njegovega vpliva. Po potrebi se vse ovire za poročila takoj in učinkovito odpravijo. Zaščita organizacije pred nepravilnostmi na splošno velja kot učinkovit protikorupcijski ukrep.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Nazaj na vprašalnike</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Posvetujte se z rezultati</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
	components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>