<template>
    <div class="shadow-inner p-6">
        <div class="bg-green-light">
          <div class="container mx-auto xl:px-8 py-8 sm:py-16">
            <div class="flex flex-wrap items-start">
                <div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
                    <h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">Comment <em class="text-orange not-italic">fonctionne</em> l’outil <em class="text-orange not-italic">d’auto-évaluation</em>?</h1>
                </div>
                <div class="w-full lg:w-1/2 px-6 text-left">
                    <p class="text-teal">
                        Le questionnaire en ligne est structuré en deux volets: l’un concerne la protection des lanceurs d’alerte, tandis que l’autre concerne les politiques en matière de données ouvertes
                    </p>
                    <p class="text-teal mt-4">
                        Chaque questionnaire se compose de deux parties:
                    </p>
                    <p class="text-teal mt-4">
                        <em class="text-orange not-italic">-</em> La première est consacrée au cadre législatif en la matière.
                    </p>
                    <p class="text-teal mt-4">
                        <em class="text-orange not-italic">-</em> La seconde est centrée sur la mise en œuvre de l’auto-évaluation au sein de l’administration, sur les procédures et sur les actions adoptées, ainsi que sur les résultats obtenus. Il est conseillé de répéter périodiquement cette dernière partie, car elle est fortement liée à l’activité de l’administration.                  
                    </p>
                </div>
                <div class="w-full lg:w-1/2 px-6 text-left">
                    <p class="text-teal">
                        Chaque questionnaire contient environ 20 questions pour un temps de réponse de 15 minutes environ. 
                    <p class="text-teal mt-4">
                    </p>
                        Il est possible de:
                    <p class="text-teal mt-4">
                    </p>
                        <em class="text-orange not-italic">-</em> remplir une partie du questionnaire et le reprendre ultérieurement
                    <p class="text-teal mt-4">
                    </p>
                        <em class="text-orange not-italic">-</em> créer, à la fin des réponses, un pdf avec les résultats
                    <p class="text-teal mt-4">
                    </p>
                        <em class="text-orange not-italic">-</em> vérifier l’évolution des scores obtenus au fil du temps 
                    <p class="text-teal mt-4">
                    </p>
                        L'auto-évaluation contient un système de notation pour systématiser l'évaluation et fournir des feedback sur les domaines qui doivent être renforcés afin d'améliorer les performances et l'impact de la mesure au sein de l'organisation.
                    </p>
                </div>
                <div class="w-full mt-12 sm:mt-24 text-center">
                    <router-link :to="`/surveys`" class="bg-orange text-white px-4 sm:px-12 py-2 rounded-full uppercase my-10  mx-auto focus:outline-none" href="#">
                        Accéder aux sondages <arrowRightIco class="w-6 text-white -mt-1 ml-3"/>
                    </router-link>
                </div>
            </div>
          </div>
        </div>              
    </div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
    components: {arrowRightIco},
}
</script>