<template>
	<div class="shadow-inner p-6">
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-8 sm:py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
		  			<h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">Privacy <em class="text-orange not-italic">Policy</em></h1>
		  		</div>
		  		<div class="w-full lg:w-full px-6 text-left">
		  			<h1 class="text-3x font-bold my-4">Informations données conformément à l’art. 13 du Règlement UE n° 2016/679</h1>
		  			<p class="text-teal">		  				
						Cher Participant,<br>
						nous souhaitons vous informer que le Règlement UE n° 679/2016 «Règlement général sur la protection des données» prévoit la protection des personnes physiques à l’égard du traitement des données personnelles. Selon la réglementation indiquée, ce traitement sera basé sur les principes de légalité, de rectification et de transparence, d’adéquation, de pertinence et de limitation, d’exactitude et de mise à jour, de minimisation et de responsabilité (accountability). Veuillez donc prendre note des informations suivantes:
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">a) Identité et coordonnées du Responsable du traitement</h5>
						Le «Responsable du traitement» est l’Université de Turin – qui siège à l’adresse Via Verdi 8, 10124 Turin. Standard téléphonique +39 011670.61.11, Courrier électronique certifié (Pec): segreteria.rettore@unito.it , Représentant légal : le Recteur en place.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">b) Coordonnées du Délégué à la protection des données</h5>
						Le délégué à la protection des données (dans la version anglo-saxonne Data Protection Officer – DPO), peut être contacté à l’adresse e-mail suivante: rpd@unito.it .
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">c) Finalité et base juridique</h5>
						I dati da Lei forniti (dati personali) saranno trattati per esclusive finalità di ricerca scientifica nell’ambito del progetto europeo “Whistleblowing Open Data Impact. An Implementation and Impact Assessment – acronimo WOODIe, finanziato dal Fondo di sicurezza interna – Polizia (Grant Agreement n. 823799) per il biennio 2019-2021. La ricerca è finalizzata alla costruzione di un sistema di valutazione dello stato di attuazione e dell’impatto della normativa in materia di protezione dei whistleblower e di open data nei 7 paesi europei partner, per evidenziare in che modo tali misure contribuiscono a individuare e a contrastare la corruzione negli appalti pubblici.
						Il progetto è a cura del Dipartimento di Giurisprudenza dell’Università degli Studi di Torino. Il Coordinatore del progetto è la prof.ssa Laura Scomparin (laura.scomparin@unito.it).
						A tal fine ai partecipanti sarà chiesto di rispondere alle domande di un questionario online. I dati saranno raccolti in questa prima indagine e successivamente a cadenza semestrale.
						Poter seguire le evoluzioni nella applicazione della sopra citata normativa nel tempo permetterà ai ricercatori di valutare meglio il loro impatto. A questo scopo, si accede al questionario mediante una registrazione. I dati di registrazione saranno noti solamente a Lei, da utilizzare qualora decidesse di ripetere periodicamente il questionario. La base giuridica del trattamento è la Legge n. 168/1989 e s.m.i., 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">d) Modalités du traitement</h5>
						Le traitement des données personnelles sera effectué de manière automatisée. Le responsable du traitement mettra en œuvre des mesures techniques et organisationnelles appropriées, telles que la pseudonymisation, visant à appliquer efficacement les principes de protection des données et à protéger les droits des personnes concernées. 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">e) Communication des données</h5>
						La participation à la recherche est gratuite et volontaire, la non-participation n’entraîne aucune conséquence pour l’intéressé.
						La participation au projet de la part de l’intéressé implique la communication des données personnelles demandées lors de l’inscription.
						Le défaut de communication des données et d’adhésion au projet de recherche ne permettra pas de réaliser le projet, avec des répercussions et des effets négatifs pour le progrès scientifique et technologique, pour l’évolution socioculturelle et pour le bien-être de la communauté.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">f) Destinataires des données</h5>
						Les données collectées ne seront pas divulguées à des tiers. Les données collectées à travers le questionnaire seront analysées par les statisticiens de l’équipe de travail. Les résultats peuvent être publiés sous forme globale et jamais sous forme individuelle dans des revues scientifiques. 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">g) Transfert de données vers un pays tiers</h5>
						Les données collectées ne seront pas transférées vers un pays basé en dehors de l’Union Européenne (pays tiers). 
					</p>
					<p class="text-teal">	
						<h5 class="font-bold">h) Durée de conservation des données</h5>
						Les données personnelles peuvent être conservées à des fins de recherche scientifique et / ou à des fins statistiques même au-delà de la période nécessaire pour atteindre les finalités pour lesquelles elles ont été collectées ou traitées ultérieurement, conformément à l’art. 5, § 1 lett. e) et art. 89 du Règlement UE sur le traitement des données personnelles 2016/679 (GDPR) (Les données seront conservées pendant 15 ans).
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">i) Droits de la personne sur ses données</h5>
						Vous pouvez exercer à tout moment vos droits visés aux sections 2, 3 et 4 du chapitre III du Règlement UE n° 679/2016 (par exemple droits d’information et d’accès, de rectification et d’annulation, de limitation et d’opposition au traitement, de portabilité des données personnelles, etc.) en envoyant une demande ayant pour objet «droit de respect de la vie privée et de protection des données personnelles » au responsable du traitement interne, directeur du Département de Droit: direzione.giurisprudenza@unito.it, et au directeur du projet de recherche laura.scomparin@unito.it et pour connaissance à rpd@unito.it
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">j) Réclamation</h5>
						L’intéressé a le droit de déposer une plainte auprès de l’autorité de contrôle et peut par conséquent contacter l’Autorité Nationale de Protection des Données Personnelles : https://www.garanteprivacy.it/.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">k) Profilage</h5>
						Le Responsable du traitement n’utilise pas de traitement automatisé visant le profilage conformément à l’art. 22 Règlement de l’UE en matière de traitement des données personnelles 2016/679 (GDPR).			
		  			</p>
		  		</div>		  		
		  	</div>
		  </div>
		</div>				
	</div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
	components: {arrowRightIco},
}
</script>