<template>
	<div class="shadow-inner p-6">
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-8 sm:py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
		  			<h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">Kako deluje <em class="text-orange not-italic">orodje</em> za <em class="text-orange not-italic">samoocenjevanje</em>?</h1>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left">
		  			<p class="text-teal">
		  				Spletni vprašalnik je strukturiran na dva dela: eden se ukvarja z zaščito žvižgačev, drugi pa s politikami odprtih podatkov.
		  			</p>
		  			<p class="text-teal mt-4">
						Vsak vprašalnik je sestavljen iz dveh delov:
					</p>
					<p class="text-teal mt-4">
						<em class="text-orange not-italic">-</em> Prvi je namenjen zakonodajnemu okviru na tem področju, drugi pa se osredotoča na izvajanje ukrepa znotraj uprave, na postopke in sprejete ukrepe ter na dosežene rezultate. Priporočljivo je, da sestavo tega zadnjega dela ponovite vsakih občasno, saj je močno povezan z dejavnostjo uprave.
					</p>
					<p class="text-teal mt-4">
						<em class="text-orange not-italic">-</em> Drugi del se osredotoča na to, kako se ukrep (odprti podatki ali zaščita pred nepravilnostmi) izvaja v organizaciji, sprejete postopke in ukrepe ter dosežene rezultate. Ker je ta del močno povezan z vsakodnevnimi dejavnostmi organizacije, ga je treba redno ponavljati, da preverimo izboljšave. 					
		  			</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left">
		  			<p class="text-teal">
		  				Vsak vprašalnik vsebuje približno 20 vprašanj in traja približno 15 minut. 
		  			<p class="text-teal mt-4">
					</p>
						Možno je:
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> izpolnite del vprašalnika in ga nadaljevati pozneje;
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> ustvariti pdf na koncu prevajanja, ki vsebuje ustrezne rezultate;
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> preveriti izboljšanje doseženih rezultatov skozi čas. 
					<p class="text-teal mt-4">
					</p>
						Pridobite ocene in povratne informacije o tem, kateri sektorji potrebujejo več pozornosti, da bi izboljšali učinek ukrepov v upravi.
		  			</p>
		  		</div>
		  		<div class="w-full mt-12 sm:mt-24 text-center">
	                <router-link :to="`/surveys`" class="bg-orange text-white px-4 sm:px-12 py-2 rounded-full uppercase my-10  mx-auto focus:outline-none" href="#">
	                    Pojdite na vprašalnike <arrowRightIco class="w-6 text-white -mt-1 ml-3"/>
	                </router-link>
	            </div>
		  	</div>
		  </div>
		</div>				
	</div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
	components: {arrowRightIco},
}
</script>