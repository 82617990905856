import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import i18n from '../i18n'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            id: null,
            name: null,
            email: null,
            summary: null
        },
        surveys: [],
        entries: [],
        options: {},
        scores: {},
        opens: {},        
        token: null,
        error: null,
        api: process.env.VUE_APP_API_URL
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
            state.token = user.token
        },
        SET_USER_SUMMARY(state, summary) {
            state.user.summary = summary
        },
        SET_SURVEYS(state, surveys) {
          state.surveys = surveys
          state.options = {}
          state.scores = {}
          state.opens = {}
          //initialize option mapping
          state.surveys.map(survey => {            
            survey.questions.map(question => {
                question.options.map(option => {  
                    state.options[option.id] = false
                    state.scores[option.id] = option.score || 0
                    option.values.map(value => {
                        state.options[value.id] = false
                        state.scores[value.id] = value.score || 0
                    })  
                    if(option.open) state.opens[option.id] = ''
                })
            })            
          })
        },
        SET_OPTIONS(state, val) {
          state.options = val
        },
        SET_OPTION(state, {key, val}) {
          state.options[key] = val
        }
        ,
        SET_OPEN(state, {key, val}) {
          state.opens[key] = val
        }
    },
    actions: {
      async loadSurveys({ state, commit }) {    
        const config = {
            headers: { Authorization: `Bearer ${state.token}` }
        };         
        axios.get(`${state.api}/surveys?locale=${i18n.locale}`, config).then(res => commit('SET_SURVEYS', res.data.data))
      },
      async loadSurveysAll({ state, commit }) {    
        const config = {
            headers: { Authorization: `Bearer ${state.token}` }
        };         
        axios.get(`${state.api}/surveys`, config).then(res => commit('SET_SURVEYS', res.data.data))
      },
      async storeSurvey({ state, dispatch }, survey) {       
        const config = {
            headers: { Authorization: `Bearer ${state.token}` }
        };  
        const body = {            
            survey_id: survey.id,
            options: state.options,
            opens: state.opens,
            scores: state.scores
        }   
        axios.post(`${state.api}/entries`, body, config)
      }
    },
    getters: {        
        getSurveys(state) {
          return state.surveys
        },
        getOptions(state) {
          return state.options
        }
    },
    modules: {}
})