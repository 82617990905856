<template>
	<div class="body">
		<div class="bg-green-light shadow-inner">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-center">
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6">
		  			<h1 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10">Welcome to the Woodie<br><em class="text-orange not-italic">self-assessment tool</em> on<br><em class="text-orange not-italic">anti-corruption</em> measures</h1>
		  			<p class="lead text-teal font-bold mt-6">How well do public authorities across Europe manage measures to fight corruption on public contracts?</p>
		  			<p class="lead text-teal font-bold mt-2">How whisteblower protection and open data policy are structured and implemented within public authorities?</p>
		  			<p class="text-teal mt-10">This online self-assessment, developed by the consortium of the European project Woodie, concerns the two specific measures to fight corruption and safeguard the public interest that are at the core of the project:  the whistleblower protection and the open data.</p>
		  			<button @click="scrollToAction()" class="bg-orange text-white px-12 py-2 rounded-full uppercase mt-10 focus:outline-none">use the tool</button>
		  		</div>
		  		<div class="hidden lg:block w-1/2 px-24 text-center">
		  			<img src="@/assets/images/hero.png" class="mx-auto">
		  		</div>
		  	</div>
		  </div>
		</div>
​
		<div class="bg-green-dark">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6">
		  			<h2 class="text-white text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">What is</em> the Woodie self-assessment for?</h2>
		  			<p class="text-white mt-2">The online tool is designed to help public authorities to:</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Self-assess their <strong>level of awareness</strong> on their legislative framework on whistleblower protection and open data.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Serve as a basis for <strong>knowledge improvements</strong> among internal key staff.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Find out if they have the necessary system in place to fully <strong>implement obligations, achieve results</strong> and measure over time progresses on the implementation.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white"><strong>Provide feedback</strong> on areas, mechanisms and procedures that need to be strengthened in order to improve the functioning and maximize the impact of the two measures towards employees, stakeholders and citizens.</p>
		  		</div>
		  	</div>
		  </div>
		</div>
​
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6">
		  			<h2 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">Who</em> can fill the Woodie self-assessment?</h2>
		  		</div>
		  		<div class="w-full px-6 text-left mt-6">
		  			<p class="text-teal">The tool is targeting public sector in European countries, it applies to all kinds of public administrations, no matter of the scale or mission. Therefore, it can be filled by <strong>civil servants (managers/officers) in charge of tasks</strong> and responsibilities in the area of whistleblowing and/or of open data.</p>
		  		</div>
		  	</div>
		  </div>
		</div>
​
		<div class="bg-green-medium" id="cta">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start justify-center">
		  		<div class="w-full md:w-2/3 px-6 text-left pt-6">
		  			<h2 class="text-white text-center text-3xl sm:text-5xl font-bold sm:leading-10"><em class="text-green-dark not-italic">Start</em> using the tool</h2>
		  			<p class="text-white mt-6 text-center">If you still don’t have an account you can proceed to your registration. If you already have one, just click on Log-in and start using the tool.</p>
		  			<div class="mt-4 text-center">
		  				<router-link to="/register" class="block w-full mx-auto bg-transparent border text-white text-sm font-bold px-12 py-3 rounded-full uppercase mt-6 md:mt-10 md:inline-block md:w-auto md:mr-10">register</router-link>
		  				<router-link to="/login" class="block w-full mx-auto bg-white border border-white text-sm text-green-dark font-bold px-12 py-3 rounded-full uppercase mt-4 md:mt-10 md:inline-block md:w-auto">log in</router-link>
		  			</div>
		  		</div>
		  	</div>
		  </div>
		</div>
	</div>
</template>
<script>
export default {
  methods: {
  	scrollToAction() {
 		document.getElementById('cta').scrollIntoView({behavior: 'smooth'});	
  	}
  },
}
</script>
