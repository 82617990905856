<template>
    <div class="py-12 shadow-inner px-6">        
        <div class="heading w-full text-center" v-if="!surveys">
            <h1 class="text-green-dark text-3xl sm:text-5xl font-bold sm:leading-10 sm:py-10">{{ $t("No surveys found") }}</h1>
        </div>
        <div v-for="survey in surveys" :key="survey.id" class="content w-full sm:w-3/4 text-left bg-white rounded-lg mt-10 m-auto px-4 sm:px-16 py-6 sm:py-10" v-if="survey.entries.length">
        	<h4 class="text-green-medium uppercase font-bold">{{ survey.title }}</h4>
            <table class="table-auto w-full mt-6">
                <tbody>
                    <tr v-for="entry in survey.entries" class="border-b border-green-dark">
                        <td class="text-sm py-2">{{ $t("Created at") }} <strong>{{ entry.created_at }}</strong></td>
                        <td class="text-sm text-center w-32"><button class="underline" @click="showSummary(survey, entry)">{{ $t("Review") }}<eyeIco class="text-orange ml-2 w-5"/></button></td>
                        <td class="text-sm text-center w-32"><button class="underline" @click="showSummaryPdf(survey, entry)">{{ $t("Download PDF") }}<downloadIco class="text-orange ml-2 w-5"/></button></td>
                        <td class="text-sm text-center w-32"><button class="underline" @click="showSummaryScore(survey, entry)">{{ $t("Feedback") }}<checkIco class="text-orange ml-2 w-5"/></button></td>
                    </tr>                   
                </tbody>
            </table>
        </div>        
    </div>
</template>
<script>
import eyeIco from '@/svg/eye.vue'
import downloadIco from '@/svg/download.vue'
import arrowRightIco from '@/svg/arrow-right.vue'
import checkIco from '@/svg/check.vue'
import { mapGetters } from 'vuex'

export default {
	components: {eyeIco, downloadIco, arrowRightIco, checkIco},
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            surveys: 'getSurveys'
        })
    },
    methods: {
        showSummary(survey, entry) {
            this.$store.commit('SET_USER_SUMMARY', {survey, entry}) 
            this.$router.push('/admin/summary')
        },
        showSummaryPdf(survey, entry) {
            this.$store.commit('SET_USER_SUMMARY', {survey, entry}) 
            this.$router.push('/admin/summary-pdf')
        }
        ,
        showSummaryScore(survey, entry) {
            this.$store.commit('SET_USER_SUMMARY', {survey, entry}) 
            this.$router.push('/admin/summary-score')
        }
    }
}
</script>