<template>
	<div>
		<h1 class="text-green-dark text-2xl sm:text-3xl font-bold sm:leading-10">{{ $t("Your final score") }}</h1>
	    <h3 class="text-5xl sm:text-7xl lg:text-9xl font-bold text-green-medium text-center my-10">{{ score }}/{{ maxscore }}</h3>
	    <p v-if="!$route.meta.admin"  class="text-green-dark mt-12 lg:w-3/4 text-center mx-auto">{{ $t("Compare your result with our feedback") }}</p>
	    <div v-if="!$route.meta.admin"  class="actions my-10">
	        <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative bg-white text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">{{ $t("Back to surveys") }}</router-link> 
	        <router-link v-if="survey.type == `wb`" :to="`/feedback/wb`" class="w-full block sm:w-auto sm:inline-block relative bg-orange text-white px-12 py-2 rounded-full uppercase mt-4 mx-auto">{{ $t("Feedback") }} <arrowRightIco class="w-6 text-white -mt-1 ml-3"/></router-link> 
	        <router-link v-if="survey.type == `od`" :to="`/feedback/od`" class="w-full block sm:w-auto sm:inline-block relative bg-orange text-white px-12 py-2 rounded-full uppercase mt-4 mx-auto">{{ $t("Feedback") }} <arrowRightIco class="w-6 text-white -mt-1 ml-3"/></router-link> 
	    </div>
	</div>
</template>
<script>
import arrowLeftIco from '@/svg/arrow-left.vue'
import arrowRightIco from '@/svg/arrow-right.vue'
import { mapGetters, mapState } from 'vuex'

export default {
	components: {arrowLeftIco,arrowRightIco},
	props: ['survey','options','scores'],
    computed: {             
        score() {
            let score = 0
            // compute score by sum all option values for this survey
            this.survey.questions.map(question => {
                question.options.map(option => {     
                    if(this.options[option.id]) {          
                        // sum option scores
                        score += this.scores[option.id]
                        option.values.map(value => {
                            // sum option values scores
                            if(this.options[value.id]) 
                                score += this.scores[value.id]
                        })
                    }
                })
            }) 
            return score % 1 !== 0 ? score.toFixed(1) : score
        },
        maxscore() {
            let score = 0
            // compute max score by sum greatest option from each question
            this.survey.questions.map(question => {
                let values = []
                let optionHasValues = false
                question.options.map(option => {       
                    if(option.values.length) {
                        // get max value score if set
                        let max = [0];
                        option.values.map(value => {
                            max.push(this.scores[value.id])
                        })
                        values.push(Math.max(...max))
                        optionHasValues = true
                    }
                    else {
                        values.push(this.scores[option.id])
                    }                    
                })
                if(question.multiple || optionHasValues)
                    score += values.reduce((a, b) => a + b, 0)
                else
                    score += Math.max(...values)
            }) 
            return score % 1 !== 0 ? score.toFixed(1) : score
        }     
    }
}
</script>