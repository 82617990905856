<template>
	<div class="body">
		<div class="bg-green-light shadow-inner">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-center">
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6">
		  			<h1 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10">Bienvenue dans l’outil<br><em class="text-orange not-italic">d’auto-evaluation</em> du projet Woodie sur les mesures en matiere <em class="text-orange not-italic">d’anti-corruption</em></h1>
		  			<p class="lead text-teal font-bold mt-6">Comment les administrations publiques de l’Europe entière gèrent-elles la lutte contre la corruption dans la passation des marchés publics?</p>
		  			<p class="lead text-teal font-bold mt-2">Comment la protection des lanceurs d’alerte et les politiques sur les données ouvertes sont-elles structurées et mises en œuvre au sein des administrations publiques?</p>
		  			<p class="text-teal mt-10">Cet outil d’auto-évaluation en ligne, développé par le consortium du projet européen Woodie, a pour objet les deux mesures considérées comme centrales pour lutter contre la corruption et sauvegarder l’intérêt public: la protection des lanceurs d’alerte et les données ouvertes.</p>
		  			<button @click="scrollToAction()" class="bg-orange text-white px-12 py-2 rounded-full uppercase mt-10 focus:outline-none">utiliser l'outil</button>
		  		</div>
		  		<div class="hidden lg:block w-1/2 px-24 text-center">
		  			<img src="@/assets/images/hero.png" class="mx-auto">
		  		</div>
		  	</div>
		  </div>
		</div>
​
		<div class="bg-green-dark">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6">
		  			<h2 class="text-white text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">A quoi</em> sert l’outil d’auto-évaluation de Woodie?</h2>
		  			<p class="text-white mt-2">L’outil a été conçu pour aider les administrations publiques à:</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Auto-évaluer <strong>leur niveau de connaissance</strong> du cadre législatif concernant la protection des lanceurs d’alerte et des données ouvertes.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Découvrir s’ils disposent de la <strong>stratégie nécessaire</strong> permettant de mettre en œuvre les obligations imposées par la réglementation pour atteindre des résultats et mesurer au fil du temps les progrès obtenus.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Améliorer les connaissances et <strong>l’application des mesures</strong> parmi le personnel des administrations publiques.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white"><strong>Avoir un feedback</strong> sur les domaines, les mécanismes et les procédures qui doivent être renforcés afin d’améliorer leur fonctionnement et maximiser l’impact des deux mesures à l’égard des salariés, des acteurs concernés et des citoyens.</p>
		  		</div>
		  	</div>
		  </div>
		</div>
​
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6">
		  			<h2 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">Qui</em> peut remplir l’outil d’auto-évaluation de Woodie?</h2>
		  		</div>
		  		<div class="w-full px-6 text-left mt-6">
		  			<p class="text-teal">L’outil s’adresse aux administrations publiques des pays européens, indépendamment de leur taille. Les réponses doivent être données par <strong>les agents publics auxquels</strong> des tâches et des responsabilités dans les domaines du signalement d’irrégularités (lanceurs d’alerte) et/ou de données ouvertes sont attribuées. Le volet lanceur d’alerte et le volet données ouvertes peuvent être remplis par le même agent ou bien par deux ou plusieurs agents.</p>
		  		</div>
		  	</div>
		  </div>
		</div>
​
		<div class="bg-green-medium" id="cta">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start justify-center">
		  		<div class="w-full md:w-2/3 px-6 text-left pt-6">
		  			<h2 class="text-white text-center text-3xl sm:text-5xl font-bold sm:leading-10"><em class="text-green-dark not-italic">Commencez</em> à utiliser l'outil</h2>
		  			<p class="text-white mt-6 text-center">Si vous n'avez toujours pas de compte, vous pouvez procéder à votre inscription. Si vous en avez déjà une, cliquez simplement sur connexion et commencez à utiliser l'outil.</p>
		  			<div class="mt-4 text-center">
		  				<router-link to="/register" class="block w-full mx-auto bg-transparent border text-white text-sm font-bold px-12 py-3 rounded-full uppercase mt-6 md:mt-10 md:inline-block md:w-auto md:mr-10">s'inscrire</router-link>
		  				<router-link to="/login" class="block w-full mx-auto bg-white border border-white text-sm text-green-dark font-bold px-12 py-3 rounded-full uppercase mt-4 md:mt-10 md:inline-block md:w-auto">connexion</router-link>
		  			</div>
		  		</div>
		  	</div>
		  </div>
		</div>
	</div>
</template>
<script>
export default {
  methods: {
  	scrollToAction() {
 		document.getElementById('cta').scrollIntoView({behavior: 'smooth'});	
  	}
  },
}
</script>
