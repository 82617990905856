<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Povratne informacije o <br> <span class="text-orange">izvajanju odprtih podatkov</span></h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
        	<div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-25 točk</h6>
                <p class="text-green-dark">Če je vaš končni rezultat med 0 in 25 točk, se zdi, da je splošna raven izvajanja politike odprtih podatkov v organizaciji še vedno slaba/omejena. Obstaja veliko prostora za izboljšave, zlasti glede naslednjih ključnih značilnosti: dostop do javnih dokumentov in informacij, kakovost in količina odprtih podatkov, dejanski dostop državljanov. Manjka izvedba/spremljanje obveznih obveznosti. Za organizacijo je ključnega pomena, da več pozornosti nameni povečanju učinkovitosti sistema kot tudi izboljšanju splošnega zaupanja v njegov pozitiven vpliv na boj proti korupciji. Organizacija bi morala tudi bistveno izboljšati in sprejeti konkretne ukrepe v zvezi z naslednjimi vidiki: dodeljevanjem znanj in virov, vzpostavitvijo posebne upravljavske strukture in spremljanjem stopnje preglednosti, ki jo dosežejo odprti podatki, in njihove uporabe v javnosti.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">26-45 točk</h6>
                <p class="text-green-dark">Če je vaš končni rezultat med 26 in 45 točk pomeni, da ima organizacija standardno raven zavzetosti za izvajanje pričakovane politike odprtih podatkov. Politika se izvaja v skladu z zakonskimi zahtevami; obstaja pa nekaj glavnih področij za izboljšave, zlasti tistih, ki zadevajo: prisotnost namenskega notranjega osebja (s posebnimi nalogami in znanji o odprtih podatkih), ki bi lahko močno prispevalo k izboljšanju dostopa do javnih podatkov, večina/količina sproščenih podatkov, redno spremljanje izvajanja in dejansko uporabo s strani državljanov. Organizacija bi morala veliko pozornosti nameniti povečanju stopnje zaupanja v vpliv in vrednost odprtih podatkov kot sredstva za zmanjšanje korupcije s strani javnih organov. Ukrepi za izboljšanje, ki jih je treba sprejeti, lahko vključujejo: dodelitev znanj in virov ter oceno ravni preglednosti odprtih podatkov in dejanske uporabe s strani državljanov in zainteresiranih strani.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">46-70 točk</h6>
                <p class="text-green-dark">Če je vaš končni rezultat med 46 in 70 točk, to pomeni, da organizacija pozitivno izvaja politiko odprtih podatkov. Pravila in postopki se pravilno izvajajo. Ustrezna pozornost je namenjena načinom dostopa do odprtih podatkov ter njihovi kakovostni vsebini in rednemu posodabljanju. Izobražujejo se tudi notranji vodje in zaposleni o tem, kako narediti javni organ bolj pregleden in odgovoren. Obstaja dobra raven zaupanja v povečanje preglednosti z odprtimi podatki, ki so pomemben instrument za boj proti korupciji v javnem sektorju. Nadaljnji napredek je mogoče doseči s tesnim osredotočanjem na oceno analitike, da se doseže bolj poglobljeno razumevanje dejanske stopnje dosežene preglednosti in uporabe podatkov s strani državljanov.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 točk</h6>
                <p class="text-green-dark">Če je vaš končni rezultat med 71 in 100 točk, to pomeni, da organizacija uspešno izvaja politiko in strategijo odprtih podatkov. Vsi izvedbeni ukrepi so postavljeni na ustrezen način. Dostopnost in vsebina odprtih podatkov, skupaj z njihovimi posodobitvami, ima veliko prednost v organizaciji. Slednja zagotavlja tudi potrebno usposabljanje za vodje in zaposlene, ki delajo na odprtih podatkih, ter ustrezna orodja za dostop do podatkov. Sistematični pregled in analiza rezultatov odraža zaupanje organizacije v preglednost in odprte podatke kot sredstvo za boj proti korupciji v javnem sektorju. Nazadnje se zdi razvoj ukrepov, namenjenih ozaveščanju notranjega osebja in državljanov o vplivu odprtih podatkov na javno preglednost, še posebej dosleden.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Torna ai questionari</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Consulta risultati</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
	components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>