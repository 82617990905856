<template>
	<div class="flex flex-wrap items-center bg-green-light h-screen">
	  	<div class="w-full lg:w-5/12 px-6 text-center py-6 lg:h-screen bg-white flex flex-wrap items-center">	  		
	  		<form class="w-full sm:px-20 text-left mt-4">
	  			<img src="@/assets/images/logo.png" class="h-10 w-auto h-auto max-h-10 mx-auto">

          <label class="block text-green-dark font-bold mt-12">{{ $t("Email") }}</label>
          <input v-model="email" type="email" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1">
          <span v-if="errors.email" v-for="error in errors.email" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>

	  			<label class="block text-green-dark font-bold mt-4">{{ $t("New Password") }}</label>
          <input v-model="password" type="password" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1">
          <span v-if="errors.password" v-for="error in errors.password" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>

          <label class="block text-green-dark font-bold mt-4">{{ $t("Password Confirmation") }}</label>
          <input v-model="password_confirmation" type="password" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1">	

          <button @click.prevent="passwordReset" class="relative bg-orange text-white px-12 py-2 rounded-full uppercase mt-10 block w-full focus:outline-none" :disabled="disabled">{{ $t("Reset password") }} <loaderIco class="h-5 w-5 text-white" v-if="disabled"/>
		  		</button>

	  		    <span v-if="error" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>
	  		</form>	  		
	  		<div class="mt-16 lg:mt-24 text-center px-6 pb-4 w-full">
				{{ $t("Don't have an account yet?") }}
				<router-link to="/register" class="text-orange underline">{{ $t("Register here") }}</router-link>
			</div>
	  	</div>
	  	<div class="hidden lg:block lg:w-7/12 text-center">
	  		<img src="@/assets/images/auth.png" class="w-auto h-auto mx-auto px-20">	  		
	  	</div>
	</div>	
</template>
<script>
import loaderIco from '@/svg/loader.vue'

export default {
  components: {loaderIco},
  data() {
  	return {  		
  		email: '',
  		password: '',  		
      password_confirmation: '',     
      token: null,
  		error: null,
  		disabled: false,
      errors: {          
        email: [],
        password: []
      },
  	}
  },
  mounted() {
    if (this.$route.query.t) this.token = this.$route.query.t
    else this.$router.push('login')
  },
  computed: {
    
  },
  methods: {
  	passwordReset() {
  		const payload = {
  			email: this.email,
  			password: this.password,
        password_confirmation: this.password_confirmation,
        token: this.token,
        locale: this.$i18n.locale
  		}
  		this.disabled = true
  		this.axios.post(process.env.VUE_APP_API_URL + '/password/reset', payload)
  			.then(response => {  				
  				this.$router.push('login?reset=1')
  			})
  			.catch(error => {  				
  				this.disabled = false
				  if (error.response && error.response.status === 422)
            this.errors = error.response.data.data	
  			})  			
  	}
  },
}
</script>