<template>
	<div class="shadow-inner p-6">
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-8 sm:py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
		  			<h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">How does the <em class="text-orange not-italic">self-assessment</em> tool <em class="text-orange not-italic">works</em>?</h1>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left">
		  			<p class="text-teal">
		  				The online tool is structured in two thematic sections, one dedicated to the whistleblowing protection and the other to the open data policy. They can be filled both or alternatively.
		  			</p>
		  			<p class="text-teal mt-4">
						Each thematic section is composed by 2 distinct parts:
					</p>
					<p class="text-teal mt-4">
						<em class="text-orange not-italic">-</em> The first part covers the level ok knowledge on the legislative framework and it means to understand its aims, sectors of coverage, means of implementations, allocation of responsibilities and resources and other relevant features. This part is compiled at the first access and then it is expected to remain unchanged. However, you can revised it in specific circumstances e.g. in case of adoption of a new law or of significant law amendments.
					</p>
					<p class="text-teal mt-4">
						<em class="text-orange not-italic">-</em> The second part focuses on how the measure (open data or whistleblowing protection) is implemented within the organization, the procedures and actions adopted and the results obtained. As this part is strongly linked to the day-to-day activities of the organization, it should be repeated periodically to check the improvements. 					
		  			</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left">
		  			<p class="text-teal">
		  				Each questionnaires contains around 20 questions and it takes approximately 15 minutes. 
		  			<p class="text-teal mt-4">
					</p>
						You can:
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> Edit your answers in different sessions of work before submitting the self-assessment
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> Make a pdf of/ print your assessment results
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> Check how your scores modify over time 
					<p class="text-teal mt-4">
					</p>
						The self-assessment contains a scoring system to systematize the evaluation and provide a country-level score and brief feedback on which areas need to be strengthened in order to improve performance and impact of the measure within the organization.
		  			</p>
		  		</div>
		  		<div class="w-full mt-12 sm:mt-24 text-center">
	                <router-link :to="`/surveys`" class="bg-orange text-white px-4 sm:px-12 py-2 rounded-full uppercase my-10  mx-auto focus:outline-none" href="#">
	                    Go to surveys <arrowRightIco class="w-6 text-white -mt-1 ml-3"/>
	                </router-link>
	            </div>
		  	</div>
		  </div>
		</div>				
	</div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
	components: {arrowRightIco},
}
</script>