<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Bilan de l’évaluation sur la mise en œuvre de la<br> <span class="text-orange"> politique d’ouverture des données</span></h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-25 points</h6>
                <p class="text-green-dark">Si votre score final se situe dans la fourchette 0-25 points, le niveau global de mise en œuvre de la politique d’ouverture des données dans l’organisation semble être encore faible/limité. Il y a une marge d’amélioration considérable, en particulier en ce qui concerne les points-clé suivants : accès aux informations et documents administratifs, qualité et quantité des données ouvertes, accès effectif par les citoyens. Il manque la mise en œuvre/le suivi de mesures obligatoires. L’organisation doit absolument chercher à accroître l’efficacité du système et à améliorer la confiance générale concernant son impact positif pour lutter contre la corruption. L’organisation devrait aussi produire des améliorations nettes et lancer des actions concrètes sur les aspects suivants : allocation de formations et ressources, établir une structure de gestion dédiée, et contrôler le niveau de transparence atteint par les données ouvertes et leur utilisation par le public.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">26-45 points</h6>
                <p class="text-green-dark">Si votre score final se situe dans la fourchette 26-45 points, cela indique que l’organisation a un niveau standard d’engagement dans la mise en œuvre de la politique d’ouverture des données prévue. La politique est appliquée conformément aux exigences prévues par la loi ; cependant, il existe des points majeurs à améliorer, en particulier ceux concernant : la présence d’une équipe interne dédiée (avec des missions et connaissances spécifiques sur les données ouvertes) qui pourrait contribuer fortement à améliorer l’accès aux documents administratifs, le volume/la quantité de données susceptibles à être publiées, le contrôle périodique du processus de mise en œuvre et son utilisation effective par les citoyens. L’organisation devrait chercher activement à augmenter le niveau de confiance vis-à-vis de l’impact et de la valeur des données ouvertes en tant que moyen pour réduire la corruption dans le secteur public. Les actions en vue d’une amélioration à mettre en place peuvent inclure : l’allocation de formations et de ressources ; l’évaluation du niveau de transparence des données ouvertes et de leur utilisation effective par les citoyens et les parties prenantes.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">46-70 points</h6>
                <p class="text-green-dark">Si votre score final se situe dans la fourchette 46-70 points, cela indique que l’organisation met en œuvre positivement la politique d’ouverture des données. Les règles et les procédures sont appliquées correctement. Une attention appropriée est accordée aux moyens pour accéder aux données ouvertes, ainsi qu’à la qualité de leur contenu et à leur mise à jour périodique. En outre, les directeurs internes et les employés reçoivent une formation leur permettant de rendre l’organe public plus transparent et responsable. Il y a un bon niveau de confiance sur une majeure transparence via les données ouvertes, ce qui représente un instrument valable pour combattre la corruption dans le secteur public. Un progrès supplémentaire peut être accompli en se concentrant davantage sur l’évaluation et l’analyse des données, pour obtenir une compréhension plus approfondie du niveau effectif de transparence atteint et sur comment les citoyens utilisent les données.  </p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 points</h6>
                <p class="text-green-dark">Si votre score final se situe dans la fourchette 71-100 points, cela indique que l’organisation applique avec succès la politique et la stratégie de l’ouverture des données. Toutes les mesures d’exécution sont mises en place correctement. L’organisation accorde une place hautement prioritaire à l’accessibilité et au contenu des données ouvertes – ainsi qu’à leur mise à jour. En outre, l’organisation fournit aussi la formation nécessaire à ses directeurs et employés qui travaillent sur les données ouvertes, ainsi que les outils adéquats pour accéder aux données. Un contrôle et la mise en place d’une analyse systématique des résultats reflète la confiance qu’a l’organisation dans le fait que la transparence et l’ouverture des données constituent un moyen pour combattre la corruption dans le secteur public. Enfin, le développement d’actions visant à accroître la sensibilisation du personnel interne et des citoyens quant à l’impact de l’ouverture des données sur la transparence publique semble particulièrement constant.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Retour aux questionnaires</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Consulter les résultats</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
    components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>