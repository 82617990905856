<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Bilan de l’évaluation sur la mise en œuvre de la protection<br> <span class="text-orange">des lanceurs d’alerte</span> (ou « whistleblower »)</h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
        	<div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-20 points</h6>
                <p class="text-green-dark">Si votre score final se situe dans la fourchette 0-20 points, cela signifie que le niveau global et la précision de la mise en œuvre de la protection du lanceur d’alerte (ou « whistleblower », WB) au sein de l’organisation sont encore faibles. La gestion des pratiques relatives aux lanceurs d’alerte peut être considérablement améliorée, notamment en ce qui concerne : les canaux disponibles pour effectuer des signalements WB, les divulgations du contenu des signalements et les procédures d’investigation. Une plus grande attention devrait aussi être accordée quant à l’instauration une protection contre des représailles, et pour protéger la confidentialité de l’identité du lanceur d’alerte. L’organisation devrait consacrer plus d’efforts et de ressources à l’outil WB, à la gestion et au suivi des divulgations et à l’identification de toute modification possible ou d’actions correctives possiblement requises.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">21-40 points</h6>
                <p class="text-green-dark">Si votre score final se situe dans la fourchette 21-40 points, dans l’ensemble, l’organisation démontre un niveau standard d’engagement pour l’application des règlements de protection du WB. Les règles et procédures encadrant le lanceur d’alerte semblent être conformes aux exigences ; toutefois, il y a des points importants à améliorer, en particulier en ce qui concerne l’organisation interne : elle devrait être structurée de manière à encourager des signalements d’actes répréhensibles et à préserver la confidentialité du lanceur d’alerte de manière plus efficace. Le niveau de conscience des employés en ce qui concerne le système de signalement mis en place n’est pas complètement adéquat. L’organisation devrait faire plus d’efforts pour augmenter le niveau de confiance dans le système de protection WB et ses bénéfices parmi les employés. Les actions d’amélioration à mettre en œuvre peuvent inclure l’allocation de formations et de ressources, et l’élimination de tout facteur potentiel qui pourrait entraver des divulgations.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">41-70 points</h6>
                <p class="text-green-dark">Si votre score final se situe dans la fourchette 41-70 points, cela indique que l’organisation a agi positivement pour mettre en œuvre la protection des lanceurs d’alerte. Les règles et les procédures sont correctement appliquées. Une attention est accordée aux piliers principaux du système, à savoir le fonctionnement des canaux de signalement, l’importance des campagnes de sensibilisation et la formation des employés et des directeurs. L’organisation pourrait lancer des actions supplémentaires et faire davantage de progrès en adoptant des mesures supplémentaires (c.à.d. en plus des mesures obligatoires), afin d’accroître le niveau de confiance parmi ses employés et d’évaluer internement l’efficacité de son système de protection des lanceurs d’alerte.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 points</h6>
                <p class="text-green-dark">Si votre score final se situe dans la fourchette 71-100 points, cela indique que l’organisation met en œuvre avec succès le système de protection des lanceurs d’alerte. Toutes les mesures de mise en œuvre sont appliquées de façon adéquate. Plusieurs canaux de signalement différents sont disponibles et l’organisation contrôle leur niveau d’efficacité régulièrement. L’organisation fournit aussi toute la communication et les activités de formation nécessaires. En outre, elle a déjà fait des efforts pour renforcer la gouvernance interne du système de protection des lanceurs d’alerte et pour en améliorer l’impact. Si nécessaire, tout obstacle entravant des signalements est rapidement et efficacement éliminé. La protection du WB mise en place par l’organisation est, dans l’ensemble, considérée comme une mesure anti-corruption efficace. </p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Retour aux questionnaires</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Consulter les résultats</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
	components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>