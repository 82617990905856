<template>
	<div class="shadow-inner p-6">
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-8 sm:py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
		  			<h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">Privacy <em class="text-orange not-italic">Policy</em></h1>
		  		</div>
		  		<div class="w-full lg:w-full px-6 text-left">
		  			<h1 class="text-3x font-bold my-4">Informații furnizate în temeiul art. 13 din Regulamentul UE nr. 2016/679</h1>
		  			<p class="text-teal">		  				
						Stimate Participant,<br>
						Dorim să vă informăm că Regulamentul UE nr. 679/2016 „Regulamentul general privind protecția datelor” prevede protecția persoanelor în ceea ce privește prelucrarea datelor cu caracter personal. Conform legislației indicate anterior, această prelucrare se va baza pe principiile legalității, corectitudinii și transparenței, adecvării, relevanței și limitării, acurateței și actualizării, limitării excesului și responsabilității. Prin urmare, vă oferim următoarele informații:
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">a) Identitatea și datele de contact ale Operatorului de date</h5>
						„Operatorul de date” este Universitatea din Torino - sediul din via Verdi 8, 10124 Torino. Centrală telefonică +39 011 670.61.11, adresa de poștă electronică securizată (Pec): segreteria.rettore@unito.it, reprezentant legal: Rectorul pro tempore.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">b) Datele de contact ale Responsabilului cu protecția datelor cu caracter personal</h5>
						Responsabilul cu protecția datelor cu caracter personal - DPO, în versiunea anglo-saxonă Data Protection Officer - DPO, poate fi contactat la următoarea adresă de e-mail rpd@unito.it.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">c) Scopul și temeiul juridic</h5>
						Datele pe care le furnizați (date cu caracter personal) vor fi prelucrate doar în scopuri de cercetare științifică în cadrul proiectului european „Whistleblowing Open Data Impact. An Implementation and Impact Assessment” – acronim WOODIe, finanțat de Fondul pentru securitate internă – Poliție (Grant Agreement nr. 823799) pe perioada 2019-2021. Cercetarea vizează construirea unui sistem de evaluare a stării de implementare și a impactului legislației privind protecția whistleblower și a open data în cele 7 țări europene partenere, pentru a evidenția modul în care aceste măsuri contribuie la identificarea și combaterea corupției în achizițiile publice.
						Proiectul este organizat de Facultatea de Drept din cadrul Universității Torino. Coordonatorul proiectului este prof. Laura Scomparin (laura.scomparin@unito.it).
						În acest scop, participanții vor fi rugați să răspundă la întrebările unui chestionar online. Datele vor fi colectate în acest prim sondaj și, ulterior, la fiecare șase luni.
						Posibilitatea de a urmări în timp evoluția aplicării legislației menționate mai sus va permite cercetătorilor să evalueze mai bine impactul acesteia. Accesul la chestionar este posibil în urma înregistrării. Datele de înregistrare vor fi cunoscute numai de dvs., putând fi utilizate dacă decideți să repetați periodic chestionarul. 
						Temeiul juridic al prelucrării este Legea nr. 168/1989, cu modificările și completările ulterioare. 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">d) Metoda de prelucrare</h5>
						Prelucrarea datelor cu caracter personal va fi efectuată în mod automat. Operatorul de date va implementa măsuri tehnice și organizaționale adecvate, cum ar fi pseudonimizarea, care vizează implementarea eficientă a principiilor de protecție a datelor și protejarea drepturilor persoanelor vizate.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">e) Furnizarea datelor</h5>
						Participarea la cercetare este gratuită și voluntară, neparticiparea neavând nicio consecință pentru partea vizată.
						Participarea la proiect a părții vizate implică furnizarea datelor cu caracter personal solicitate în momentul înregistrării.
						Nefurnizarea datelor și neparticiparea la proiectul de cercetare nu vor permite realizarea proiectului, cu repercusiuni și efecte negative pentru progresul științific și tehnologic, pentru evoluția socio-culturală și pentru bunăstarea comunității.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">f) Destinatarii datelor</h5>
						Datele colectate nu vor fi dezvăluite terților. Datele colectate prin chestionar vor fi analizate de statisticienii grupului de lucru. Rezultatele pot fi publicate în forma agregată și niciodată individuală în reviste științifice.
					</p>
					<p class="text-teal">	
						<h5 class="font-bold">g) Transferul datelor către o țară terță</h5>
						Datele colectate nu vor fi transferate către o țară din afara Uniunii Europene (așa-numita Țară terță).  
					</p>
					<p class="text-teal">	
						<h5 class="font-bold">h) Perioada de păstrare a datelor</h5>
						Datele cu caracter personal pot fi păstrate în scopuri de cercetare științifică și/sau în scopuri statistice chiar și după perioada necesară realizării scopurilor pentru care au fost colectate sau prelucrate ulterior, în conformitate cu art. 5, § 1 lit. e) și art. 89 din Regulamentul UE privind prelucrarea datelor cu caracter personal 2016/679 (GDPR) (Datele vor fi păstrate timp de 15 ani).
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">i) Drepturile privind datele</h5>
						Vă puteți exercita în orice moment drepturile menționate în secțiunile 2, 3 și 4 din capitolul III al Regulamentului UE nr. 679/2016 (de ex. dreptul la informare și acces, la rectificare și ștergere, la limitare și opoziție la prelucrare, la portabilitate a datelor cu caracter personal etc.) prin trimiterea unei cereri cu subiectul: drepturi privacy Responsabilului intern cu prelucrarea, Directorului Facultății de drept: a se introduce e-mailul, Șefului studiului laura.scomparin@unito.it și, spre știință, rpd@unito.it
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">j) Reclamații</h5>
						Partea vizată are dreptul să depună o reclamație la autoritatea de supraveghere și poate contacta Autoritatea de garantare a protecției datelor cu caracter personal: https://www.garanteprivacy.it/.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">k) Profilarea</h5>
						Operatorul de date nu utilizează procese automatizate care vizează profilarea în temeiul art. 22 din Regulamentul UE privind prelucrarea datelor cu caracter personal nr. 2016/679 (GDPR)			
		  			</p>
		  		</div>		  		
		  	</div>
		  </div>
		</div>				
	</div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
	components: {arrowRightIco},
}
</script>