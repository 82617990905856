<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Feedback zur<br> <span class="text-orange">WB-Implementierung</span></h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
        	<div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-20 punkten</h6>
                <p class="text-green-dark">Wenn Ihr Endergebnis zwischen 0-20 Punkten liegt, ist das Gesamtniveau und die Genauigkeit der Umsetzung des WB-Schutzes innerhalb der Organisation mangelhaft. Es besteht noch erheblicher Vebesserungsbedarf bei dem Umgang mit Whistleblowern im Management, insbesondere in Bezug auf Kanäle, die Whistelblowern zur Verfügung stehen. Größere Aufmerksamkeit sollte auch dem Schutz vor Repressalien oder Schikanierung und der Wahrung der Vertraulichkeit der Identität des Whistleblowers geschenkt werden. Die Organisation sollte für das WB-Instrument, für das Management und die Nachverfolgung von Enthüllungen und für die Identifizierung möglicher Änderungen oder Korrekturmaßnahmen, mehr Einsatz und Ressourcen aufbringen.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">21-40 punkten</h6>
                <p class="text-green-dark">Wenn Ihr Endergebnis zwischen 21-40 Punkten liegt, zeigt die Organisation insgesamt ein adäquates Maß an Engagement bei der Umsetzung der WB-Schutzbestimmungen. Die Vorschriften und Verfahren für die Meldung von Missständen scheinen den Anforderungen zu entsprechen; es gibt jedoch große Bereiche, in denen Verbesserungen möglich sind, insbesondere im Hinblick darauf, wie die interne Organisation aufgebaut sein sollte, um Meldungen von Missständen zu fördern und die Anonymität des Whistleblowers wirksamer zu schützen. Der Sensibilisierungsgrad der Mitarbeiter bezüglich des eingeführten Meldesystems reicht nicht ganz aus. Die Organisation sollte mehr tun, um das Vertrauen der Mitarbeiter in das WB-Schutzsystem und dessen Vorteile zu steigern. Zu den zu ergreifenden Verbesserungsmaßnahmen können die Zuteilung von Kompetenzen und Ressourcen und die Beseitigung aller Hindernisse, die Enthüllungen erschweren könnten, die Enthüllungen behindern könnten.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">41-70 punkten</h6>
                <p class="text-green-dark">Wenn Ihr Endergebnis zwischen 41-70 Punkten liegt, scheint die Organisation wirksame Maßnahmen zur Umsetzung des Schutzes von Whistleblowern ergriffen zu haben. Die Regeln und Verfahren werden ordnungsgemäß angewendet. Den wichtigsten Pfeilern des Systems, d. h. dem Funktionieren der Meldekanäle, der Bedeutung von Sensibilisierungskampagnen und der Schulung von Mitarbeitern und Führungskräften, wird Aufmerksamkeit geschenkt. Die Organisation könnte zusätzliche Maßnahmen ergreifen und weitere Fortschritte erzielen, indem sie (d. h. zusätzlich zu den obligatorischen Maßnahmen) zusätzliche freiwillige Maßnahmen ergreift, um das Vertrauen unter ihren Mitarbeitern zu stärken und die Effizienz ihres WB-Schutzsystems intern zu evaluieren.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 punkten</h6>
                <p class="text-green-dark">Wenn Ihr Endergebnis zwischen 71-100 Punkten liegt, bedeutet dies, dass die Organisation das System zum Schutz von Whistleblowern erfolgreich umsetzt. Alle Umsetzungsmaßnahmen werden ordnungsgemäß durchgeführt. Es stehen verschiedene Meldekanäle zur Verfügung, und die Organisation überprüft regelmäßig deren Wirksamkeit. Die Organisation bietet auch alle notwendigen Kommunikations- und Schulungsaktivitäten an. Darüber hinaus hat sie bereits Anstrengungen unternommen, um die interne Steuerung des WB-Schutzsystems zu stärken und seine Wirkung zu verbessern. Wo dies erforderlich ist, werden etwaige Hindernisse unverzüglich und wirksam beseitigt. Der Schutz der Organisation bei der Meldung von Missständen wird insgesamt als eine wirksame Maßnahme zur Korruptionsbekämpfung angesehen.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Zurück zu den Fragebögen</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Ergebnisse konsultieren</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
	components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>