<template>
	<div class="shadow-inner p-6">
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-8 sm:py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
		  			<h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">Privacy <em class="text-orange not-italic">Policy</em></h1>
		  		</div>
		  		<div class="w-full lg:w-full px-6 text-left">
		  			<h1 class="text-3x font-bold my-4">Privacy notice provided in accordance with Art. 13 of Regulation EU no. 2016/679</h1>
		  			<p class="text-teal">		  				
						Dear Participant,<br>
						We hereby inform you that Regulation EU no. 679/2016 “General Data Protection Regulation” requires natural persons to be protected with regard to the processing of their personal data. According to the indicated regulation, that processing will be based upon principles of lawfulness, fairness and transparency, adequacy, pertinence and limitation, accuracy, update, non-excess and accountability. Therefore, we provide you with the following information:
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">a) Identity and contact details of the Data Controller</h5>
						The data "Controller" is the Università degli Studi di Torino - based at Via Verdi 8, 10124 Turin. Switchboard +39 011 670.61.11, certified email address: segreteria.rettore@unito.it, legal representative: the acting Rector
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">b) Contact details of the Data Protection Officer</h5>
						The Data Protection Officer – DPO - can be contacted at the following email address rpd@unito.it.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">c) Purpose and legal basis</h5>
						The data provided by you (personal data) will be processed exclusively for the purposes of scientific research as part of the European project "Whistleblowing Open Data Impact. An Implementation and Impact Assessment" – known by the acronym WOODIe, funded by the Internal Security Fund – Policy (Grant Agreement no. 823799) for the two-year period 2019-2021. The research is aimed at constructing a system for assessing the implementation status and impact of the regulations on whistleblower protection and open data in the 7 European partner countries, to highlight the way in which those measures contribute to identifying and combating corruption in public procurement.
						The project is led by the Law Department at the Università degli Studi di Torino. The project Coordinator is Prof. Laura Scomparin (laura.scomparin@unito.it).
						To that end, participants will be asked to answer questions in an online questionnaire. The data will be collected during this initial investigation and subsequently on a half-yearly basis.
						Being able to monitor evolutions in the application of the aforementioned regulation over time will allow the researchers to best assess their impact. For this purpose, the questionnaire is accessed subject to registering. The registration details are known only to you and can be used if you decide to repeat the questionnaire on a periodic basis. 
						The legal basis of processing is Italian Law no. 168/1989 as amended and updated. 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">d) Processing methods</h5>
						Joining the research is free and voluntary; any lack of participation does not involve any consequence for the data subject.
						Participation in the project by the data subject implies the provision of the personal data requested with the registration.
						Any failure to provide the data or to join the research project will prevent the implementation of the project itself, with repercussions and negative effects on scientific and technological progress, socio-cultural evolution and community well-being.  
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">e) Provision of data</h5>
						Joining the research is free and voluntary; any lack of participation does not involve any consequence for the data subject.
						Participation in the project by the data subject implies the provision of the personal data requested with the registration.
						Any failure to provide the data or to join the research project will prevent the implementation of the project itself, with repercussions and negative effects on scientific and technological progress, socio-cultural evolution and community well-being.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">f) Data recipients</h5>
						The data collected will not be communicated to third parties. The data collected through the questionnaire will be analysed by statisticians of the working group. The results may be published in aggregate form - and never individually - in scientific journals.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">g) Transfer of data to a third country</h5>
						The data collected will not be transferred to a country based outside the European Union (so-called third country). 
					</p>
					<p class="text-teal">	
						<h5 class="font-bold">h) Data storage period</h5>
						The personal data may be stored for purposes of scientific research and/or for statistical purposes even beyond the period necessary to achieve the purposes for which they were collected or subsequently processed, in conformity with Art. 5, paragraph 1, letter e) and Art. 89 of Regulation EU on personal data processing 2016/679 (GDPR) (The data will be stored for 15 years)..
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">i) Rights over data</h5>
						At any time you may exercise your rights indicated in sections 2, 3 and 4 of chapter III of Regulation EU no. 679/2016 (e.g. rights of information and access, right of rectification and erasure, restriction and objection to processing, portability of personal data, etc.) by sending a request concerning “privacy rights” to the internal data controller (direzione.giurisprudenza@unito.it) and to the study Manager laura.scomparin@unito.it and for information to rpd@unito.it
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">j) Complaints</h5>
						The data subject has the right to lodge a complaint with the supervisory authority and may contact the Data Protection Supervisory Authority (Autorità Garante per la protezione dei dati personali): https://www.garanteprivacy.it/.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">k) Profiling</h5>
						The Controller does not use automated processes for profiling in accordance with Art. 22 of Regulation EU on personal data processing 2016/679 (GDPR)			
		  			</p>
		  		</div>		  		
		  	</div>
		  </div>
		</div>				
	</div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
	components: {arrowRightIco},
}
</script>