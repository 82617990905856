<template>
    <div class="relative text-center">           
        <div class="heading w-full text-center mx-auto mb-20">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">{{ $t("Feedback of the questionnaire on the knowledge of") }} <span class="text-orange" v-if="survey.type  == `wb`">{{ $t("Whistleblowing legislation") }}</span>
                  <span class="text-orange" v-if="survey.type  == `od`">{{ $t("Open Data legislation") }}</span>
            </h1>            
        </div>

        <div class="flex flex-wrap items-center bg-white rounded-xl shadow-lg text-left mb-6" v-for="category in survey.categories" :key="category.id">
        	<div class="w-full sm:w-4/5">
        		<div class="py-10 px-10 sm:border-green-medium sm:border-r w-full">
		        	<h3 class="uppercase text-green-dark font-bold">{{ category.name }}</h3>
		        	<p class="mt-2">{{ category.text }}</p>
		        </div>
	        </div>
	        <div class="w-full sm:w-1/5 text-center px-6 pb-10 sm:pb-0">
	        	<span class="block">{{ $t("Your score") }}:</span>
	        	<h4 class="text-5xl font-bold text-green-medium">{{ percentage(category.id) }}%</h4>
	        </div>
        </div>
        <div v-if="!$route.meta.admin"  class="actions w-full text-center my-10">
            <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">{{ $t("Back to surveys") }}</router-link> 
            <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">{{ $t("Consult results") }}</router-link> 
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {		
	data() {
        return {}
    },
    props: ['survey','options','scores'],    
    methods: {
    	score(category) {
            let score = 0
            // compute score by sum all option values for this survey
            this.survey.questions.map(question => {
            	if(question.category == category) {
	                question.options.map(option => {                           	
	                    if(this.options[option.id])                  
	                        score += this.scores[option.id]
	                })
	            }
            }) 
            return score
        },        
        maxscore(category) {
            let score = 0
            // compute max score by sum greatest option from each question
            this.survey.questions.map(question => {                
                if(question.category == category) {                    
                	let values = []                    
                    if(
                        !question.depends_on_option || 
                        (question.depends_on_option && this.options[question.depends_on_option])
                    ) { 
    	                question.options.map(option => {                	                                               
    	                    values.push(this.scores[option.id])
    	                })
    	                if(question.multiple)
    	                    score += values.reduce((a, b) => a + b, 0)
    	                else
    	                    score += Math.max(...values)
                    }
	            }
            })          
            return score
        },
        percentage(category) {
            const score = this.score(category)
            const maxscore = this.maxscore(category)
            
            if(maxscore > 0)
        	   return parseInt(this.score(category)/this.maxscore(category) * 100)
            else
                return 0
        }        
    }
}
</script>