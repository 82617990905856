<template>    
    <div class="relative flex flex-wrap items-center py-12 shadow-inner px-6 text-center h-screen" v-if="survey">
        <a href="#" @click.prevent="prev()"><arrowLeftIco v-if="step > 0" class="w-8 text-green-medium cursor-pointer absolute top-4 sm:top-8 left-8 sm:left-16"/></a>        
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-4 sm:top-8 right-8 sm:right-16"/></router-link>

        <div class="body w-full lg:w-3/4 mx-auto" v-if="!step && !completed">
            <h1 class="text-green-dark text-2xl sm:text-3xl font-bold sm:leading-10">{{ survey.title }}</h1>
            <div class="text-green-dark mt-12 lg:w-3/4 text-justify mx-auto leading-7" v-html="survey.description"></div>
            <button @click.prevent="start" class="relative bg-orange text-white px-12 py-2 rounded-full uppercase my-10 block mx-auto focus:outline-none">{{ $t("Start Survey") }} <arrowRightIco class="w-6 text-white -mt-1 ml-3"/></button> 
        </div>
        <div class="body w-full lg:w-3/4 mx-auto" v-if="completed">
            <div v-if="survey.feedback == 'tot'">
                <Score :survey="survey" :options="options" :scores="scores"></Score>
            </div>
            <div v-else>
                <ScoreCategory :survey="survey" :options="options" :scores="scores"></ScoreCategory>
            </div>
        </div>
        <div class="body w-full lg:w-3/4 mx-auto py-10" v-for="question, index in survey.questions" v-if="questionVisible(question, index)">
            <sup class="text-green-medium text-lg uppercase font-bold">{{ $t("question") }} {{ progress }}</sup>
            <h1 class="text-green-dark text-2xl sm:text-3xl sm:leading-10 mt-6"><strong v-if="question.multiple">{{ $t("Multiple answers") }}: </strong>{{ question.text }}</h1>
            <div class="options mt-10">
                <div v-for="option, key in question.options" :key="key">
                    <!-- Multiple options -->
                    <label v-if="option.values.length == 0" :id="`l${option.id}`" class="cursor-pointer block bg-white sm:bg-white sm:text-teal my-4 p-4 rounded-lg" :class="optionSelectedClass(option)">
                        <input v-model="options[option.id]" :val="option.score" :id="`o${option.id}`" type="checkbox" class="hidden mr-2 sm:inline-block"  @click="optionSelected({question, option}, $event)">                 
                        <span>{{ option.text }}</span>
                        <input v-model="opens[option.id]" v-if="option.open" type="text" class="inline-block w-3/4 block border border-gray-dark rounded-sm bg-gray-light py-2 px-4 mt-1 text-teal" @change="openChanged({question, option}, $event)">
                    </label>
                    <!-- Multiple options with values -->
                    <div v-else class="bg-white my-4 p-4 rounded-lg text-left">
                        <span class="inline-block w-full uppercase text-orange font-bold mb-2">{{ option.text }}</span>
                        <label class="" v-for="value in option.values" class="cursor-pointer inline-block p-0 text-left" :class="value.text.length > 1 ? 'w-full lg:w-1/5' : 'w-1/5 lg:w-2/12'">
                            <input v-model="options[value.id]" :id="`v${value.id}`" type="checkbox"  @click="valueSelected({option, value}, $event)">
                            {{ value.text }}                         
                        </label>
                    </div>
                </div>
            </div>            
            <div v-if="validationError" class="w-auto inline-blog border border-red py-2 px-6 text-red font-bold my-2 rounded-lg mx-auto">{{ $t("Select an option to continue") }}</div>
            <button v-if="step < count" @click.prevent="next(question)" class="relative bg-orange text-white px-12 py-2 rounded-full uppercase mt-10 block mx-auto focus:outline-none">{{ $t("Next question") }} <arrowRightIco class="w-6 text-white -mt-1 ml-3"/></button> 
            <button v-else @click.prevent="end(question)" class="relative bg-orange text-white px-12 py-2 rounded-full uppercase mt-10 block mx-auto focus:outline-none">{{ $t("Complete") }} <arrowRightIco class="w-6 text-white -mt-1 ml-3"/></button>             
        </div>
    </div>
</template>
<script>
import ScoreCategory from '@/components/ScoreCategory.vue'
import Score from '@/components/Score.vue'
import arrowLeftIco from '@/svg/arrow-left.vue'
import arrowRightIco from '@/svg/arrow-right.vue'
import xIco from '@/svg/x.vue'
import { mapGetters, mapState } from 'vuex'

export default {
	components: {arrowLeftIco,arrowRightIco,xIco,ScoreCategory,Score},
    data() {
        return {
            step: 0,
            survey: null,
            error: false,            
            completed: false,
            validationError: false
        }
    },
    mounted() {        
        if(!this.surveys.length) this.$router.push('/surveys')

        this.surveys.map(survey => {
            if(survey.id == this.$route.params.id) {
                this.survey = survey
            }
        })

        if(!this.survey) this.$router.push('/surveys')
    },
    computed: {        
        count() {
            return this.survey.questions.length
        },
        progress() {
            let count = 0
            let progress = 0
            let dependency = 0
            // get question count excluding question with dependency
            this.survey.questions.map(question => {
                if(!question.depends_on_option) count++                
            })
            // get progress index split by standalone question and question with dependency
            for(var i = 0; i < this.step; i++) {
                let question = this.survey.questions[i]
                if(!question.depends_on_option) { progress++; dependency = 0 }
                else dependency++
            }
            if(dependency)
                return `${progress}.${dependency}/${count}`
            else
                return `${progress}/${count}`
        },               
        ...mapState(['surveys','options','scores','opens'])       
    },
    methods: {
        start() {
            this.step = 1            
        },
        prev() {
            this.validationError = false
            this.step = this.prevAvailableQuestionIndex()        
        },
        next(question) {            
            if(this.validate(question)) {
                this.validationError = false
                this.step = this.nextAvailableQuestionIndex() + 1                 
            }            
        },
        end(question) {
            if(this.validate(question)) {
                this.step = 0
                this.completed = true
                this.$store.dispatch('storeSurvey', this.survey)
            }
        },
        validate(question) {
            let validation = true
            if(!question.optional) {
                validation = false
                question.options.map(opt => { 
                    // if option has multiple values, evaluate if all options for the same question are true
                    if(opt.values.length) validation = validation && this.options[opt.id]       
                    // otherwise pass validation with only one option checked
                    if(this.options[opt.id]) validation = true
                })                
            }
            this.validationError = !validation

            return validation
        },
        nextAvailableQuestionIndex() {
            for(var i = this.step; i < this.survey.questions.length; i++) {
                let question = this.survey.questions[i]          
                if(!question.depends_on_option || this.options[question.depends_on_option]) return i
            }
        },
        prevAvailableQuestionIndex() {
            for(var i = this.step - 1; i > 0; i--) {
                let question = this.survey.questions[i - 1]
                if(!question.depends_on_option || this.options[question.depends_on_option]) return i
            }
        },
        openChanged({question, option}, $event) {  
            this.$store.commit('SET_OPEN', {key: option.id, val: $event.target.value})
        },
        optionSelected({question, option}, $event) {                  
            if(question.multiple == false) {
                // mutally exclusive options
                question.options.map(opt => { 
                    if (opt.id != option.id) {                        
                        document.getElementById(`o${opt.id}`).checked = false
                        document.getElementById(`l${opt.id}`).classList.remove('selected')
                        this.$store.commit('SET_OPTION', {key: opt.id, val: false})
                    }                    
                })  
                document.getElementById(`o${option.id}`).checked = true
                document.getElementById(`l${option.id}`).classList.add('selected')
                this.$store.commit('SET_OPTION', {key: option.id, val: true})
            }
            else {
                document.getElementById(`l${option.id}`).classList.toggle('selected')
                this.$store.commit('SET_OPTION', {key: option.id, val: $event.target.checked})
            }
            this.validationError = false
        },
        valueSelected({option, value}, $event) { 
            // mutally exclusive values
            option.values.map(val => { 
                if (val.id != value.id) {                        
                    document.getElementById(`v${val.id}`).checked = false
                    this.$store.commit('SET_OPTION', {key: val.id, val: false})
                }                    
            })  
            document.getElementById(`v${value.id}`).checked = true
            this.$store.commit('SET_OPTION', {key: value.id, val: true})   
            // check parent option for validation purpose
            this.$store.commit('SET_OPTION', {key: option.id, val: true})            
        },
        questionVisible(question, index) {
            if(question.depends_on_option) {
                return this.options[question.depends_on_option] && (index + 1) == this.step
            }
            else return (index + 1) == this.step
        },
        optionSelectedClass(option) {
            return this.options[option.id] ? 'selected': ''
        }
    }
}
</script>