<template>
    <div class="body">
        <div class="bg-green-light shadow-inner">
          <div class="container mx-auto xl:px-8 py-16">
            <div class="flex flex-wrap items-center">
                <div class="w-full lg:w-1/2 px-6 text-left pt-6">
                    <h1 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10">Benvenuto nello strumento di<br><em class="text-orange not-italic">autovalutazione</em> del progetto Woodie sulle misure in tema di <em class="text-orange not-italic">anti-corruzione</em></h1>
                    <p class="lead text-teal font-bold mt-6">In che modo le amministrazioni pubbliche in tutta Europa gestiscono le misure per combattere la corruzione negli appalti pubblici?</p>
                    <p class="lead text-teal font-bold mt-2">Come sono strutturate e attuate all'interno delle amministrazioni pubbliche la protezione dei whistleblower e le politiche sugli open data?</p>
                    <p class="text-teal mt-10">Questo strumento online di autovalutazione, sviluppato dal consorzio del progetto europeo Woodie, ha ad oggetto le due misure ritenute centrali per combattere la corruzione e salvaguardare l'interesse pubblico: la protezione dei whistleblower e gli open data.</p>
                    <button @click="scrollToAction()" class="bg-orange text-white px-12 py-2 rounded-full uppercase mt-10 focus:outline-none">usa lo strumento</button>
                </div>
                <div class="hidden lg:block w-1/2 px-24 text-center">
                    <img src="@/assets/images/hero.png" class="mx-auto">
                </div>
            </div>
          </div>
        </div>
        <div class="bg-green-dark">
          <div class="container mx-auto xl:px-8 py-16">
            <div class="flex flex-wrap items-start">
                <div class="w-full px-6 text-left pt-6">
                    <h2 class="text-white text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">A cosa serve</em> lo strumento di autovalutazione di Woodie?</h2>
                    <p class="text-white mt-2">Lo strumento è stato progettato per aiutare le pubbliche amministrazioni a:</p>
                </div>
                <div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
                    <p class="text-white">Auto-valutare il proprio <strong>livello di consapevolezza</strong> sul quadro legislativo relativo alla protezione dei whistleblower e degli open data</p>
                </div>
                <div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
                    <p class="text-white">Scoprire se dispongono del <strong>sistema necessario</strong> per dare piena attuazione agli obblighi imposti dalla normativa, per conseguire risultati e misurare nel tempo i progressi ottenuti nell’implementazione </p>
                </div>
                <div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
                    <p class="text-white">accrescere la conoscenza sulle due <strong>misure in tema di anticorruzione</strong> tra il personale interno responsabile</p>
                </div>
                <div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
                    <p class="text-white"><strong>avere un feedback</strong> su settori, meccanismi e procedure che devono essere rafforzati al fine di migliorare il funzionamento e massimizzare l'impatto delle due misure nei confronti dei dipendenti, delle parti interessate e dei cittadini</p>
                </div>
            </div>
          </div>
        </div>
        <div class="bg-green-light">
          <div class="container mx-auto xl:px-8 py-16">
            <div class="flex flex-wrap items-start">
                <div class="w-full px-6 text-left pt-6">
                    <h2 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">Chi</em> può compilare lo strumento di autovalutazione di Woodie?</h2>
                </div>
                <div class="w-full px-6 text-left mt-6">
                    <p class="text-teal">Lo strumento si rivolge alle pubbliche amministrazioni nei paesi europei, indipendentemente dalle dimensioni. La compilazione dovrebbe essere effettuata dai <strong>dipendenti pubblici a cui sono attribuiti compiti e responsabilità</strong> nei settori della segnalazione di irregolarità (whistleblower) e/o di open data. Può essere lo stesso dipendente a compilare il percorso whistleblower e il percorso open data o possono essere due o più dipendenti. </p>
                </div>
            </div>
          </div>
        </div>
        <div class="bg-green-medium" id="cta">
          <div class="container mx-auto xl:px-8 py-16">
            <div class="flex flex-wrap items-start justify-center">
                <div class="w-full md:w-2/3 px-6 text-left pt-6">
                    <h2 class="text-white text-center text-3xl sm:text-5xl font-bold sm:leading-10"><em class="text-green-dark not-italic">Inizia</em> ad usare lo strumento</h2>
                    <p class="text-white mt-6 text-center">Se non hai ancora un account puoi procedere alla registrazione. Se ne hai già uno, fai clic su Accedi e inizia a utilizzare lo strumento.</p>
                    <div class="mt-4 text-center">
                        <router-link to="/register" class="block w-full mx-auto bg-transparent border text-white text-sm font-bold px-12 py-3 rounded-full uppercase mt-6 md:mt-10 md:inline-block md:w-auto md:mr-10">Registrati</router-link>
                        <router-link to="/login" class="block w-full mx-auto bg-white border border-white text-sm text-green-dark font-bold px-12 py-3 rounded-full uppercase mt-4 md:mt-10 md:inline-block md:w-auto">Accedi</router-link>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  methods: {
    scrollToAction() {
        document.getElementById('cta').scrollIntoView({behavior: 'smooth'});    
    }
  },
}
</script>