<template>
  <div class="main">
    <Navbar v-if="navbar"/>
    <NavbarAdmin v-if="$route.meta.admin"/>

    <router-view></router-view>

    <Footer v-if="footer"/>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import NavbarAdmin from './components/NavbarAdmin.vue'
import Footer from './components/Footer.vue'

export default {
  components: { Navbar, NavbarAdmin, Footer },
  computed: {
    navbar() {
      return this.$route.meta.navbar
    },
    footer() {
      return this.$route.meta.footer
    },
  }
}
</script>