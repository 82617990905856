<template>
	<div class="body">
		<div class="bg-green-light shadow-inner">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-center">
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6">
		  			<h1 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10">Herzlich Willkommen im<br><em class="text-orange not-italic">Selbstbewertungs Tool</em> des Projekts Woodie in Bezug auf Massnahmen zur <em class="text-orange not-italic">Korruptionsbekämpfung</em></h1>
		  			<p class="lead text-teal font-bold mt-6">Wie wickeln öffentliche Verwaltungen in ganz Europa ihre Maßnahmen zur Bekämpfung der Korruption im öffentlichen Beschaffungswesen ab?</p>
		  			<p class="lead text-teal font-bold mt-2">Wie wird der Schutz von Whistleblowern und Open Data in öffentlichen Verwaltungen strukturiert und implementiert?</p>
		  			<p class="text-teal mt-10">Dieses vom Konsortium des Projekts Woodie entwickelte Online-Selbstbewertungstool konzentriert sich auf die zwei Maßnahmen, die bei der Bekämpfung der Korruption und Wahrung des öffentlichen Interesses als zentral angesehen werden: der Schutz von Whistleblowern und Open Data.</p>
		  			<button @click="scrollToAction()" class="bg-orange text-white px-12 py-2 rounded-full uppercase mt-10 focus:outline-none">Benutze das Tool</button>
		  		</div>
		  		<div class="hidden lg:block w-1/2 px-24 text-center">
		  			<img src="@/assets/images/hero.png" class="mx-auto">
		  		</div>
		  	</div>
		  </div>
		</div>

		<div class="bg-green-dark">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6">
		  			<h2 class="text-white text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">Wozu dient</em> das Selbstbewertungstool von Woodie?</h2>
		  			<p class="text-white mt-2">Dieses Werkzeug wurde entwickelt, um die öffentlichen Verwaltungsbehörden durch folgende Hilfestellungen zu unterstützen:</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Selbsteinschätzung des eigenen Kenntnisstandes in Bezug auf den Rechtsrahmen zum Schutz von Whistleblowern und Open Data.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Feststellung ob ein System verfügbar steht, um die durch die Gesetzgebung auferlegten Verpflichtungen vollständig zu erfüllen, entsprechende Ergebnisse zu erzielen und die Fortschritte zu messen, die im Laufe der Umsetzung erzielt werden konnten.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Als Basis für Wissensverbesserungen unter öffentlichen Bediensteten.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Bereitstellung von Feedback zu den Sektoren, Mechanismen und Verfahren, die gestärkt werden müssen, um die Funktionsweise zu verbessern und die Auswirkungen der beiden Maßnahmen auf Mitarbeiter, Interessensgruppen und Bürger zu maximieren.</p>
		  		</div>
		  	</div>
		  </div>
		</div>

		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6">
		  			<h2 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">Wer</em> kann das Selbstbewertungstool von Woodie nutzen?</h2>
		  		</div>
		  		<div class="w-full px-6 text-left mt-6">
		  			<p class="text-teal">Das Tool richtet sich an die öffentlichen Verwaltungen der europäischen Länder, und zwar unabhängig von ihrer Größe. Das Selbstbewertungstool sollte von öffentlich Bediensteten ausgefüllt werden, denen Aufgaben und Verantwortlichkeiten in Zusammenhang mit Verdachtsmeldungen (Whistleblower) und/oder Open Data zugewiesen wurden. Dieselben öffentlichen Bediensteten können sowohl den Whistleblower-Pfad als auch den Open-Data-Pfad ausfüllen, diese können aber auch von zwei oder mehreren verschiedenen öffentlich Bediensteten ausgefüllt werden.</p>
		  		</div>
		  	</div>
		  </div>
		</div>

		<div class="bg-green-medium" id="cta">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start justify-center">
		  		<div class="w-full md:w-2/3 px-6 text-left pt-6">
		  			<h2 class="text-white text-center text-3xl sm:text-5xl font-bold sm:leading-10"><em class="text-green-dark not-italic">Starten</em> sie das Tools</h2>
		  			<p class="text-white mt-6 text-center">Wenn Sie noch kein Konto haben, können Sie mit der Registrierung fortfahren. Wenn Sie bereits eines haben, klicken Sie einfach auf Anmelden und beginnen Sie mit der Nutzung des Tools.</p>
		  			<div class="mt-4 text-center">
		  				<router-link to="/register" class="block w-full mx-auto bg-transparent border text-white text-sm font-bold px-12 py-3 rounded-full uppercase mt-6 md:mt-10 md:inline-block md:w-auto md:mr-10">registrieren</router-link>
		  				<router-link to="/login" class="block w-full mx-auto bg-white border border-white text-sm text-green-dark font-bold px-12 py-3 rounded-full uppercase mt-4 md:mt-10 md:inline-block md:w-auto">Anmelden</router-link>
		  			</div>
		  		</div>
		  	</div>
		  </div>
		</div>
	</div>
</template>
<script>
export default {
  methods: {
  	scrollToAction() {
 		document.getElementById('cta').scrollIntoView({behavior: 'smooth'});	
  	}
  },
}
</script>