<template>
	<div>
		<HomeEN v-if="$i18n.locale == 'en'"/>
		<HomeIT v-if="$i18n.locale == 'it'"/>
		<HomeFR v-if="$i18n.locale == 'fr'"/>
		<HomeDE v-if="$i18n.locale == 'de'"/>
		<HomeSLO v-if="$i18n.locale == 'slo'"/>
		<HomeRU v-if="$i18n.locale == 'ru'"/>
	</div>
</template>
<script>
import HomeEN from './en/Home.vue'
import HomeIT from './it/Home.vue'
import HomeFR from './fr/Home.vue'
import HomeDE from './de/Home.vue'
import HomeSLO from './slo/Home.vue'
import HomeRU from './ru/Home.vue'

export default {
  components: { HomeEN, HomeIT, HomeFR, HomeDE, HomeSLO, HomeRU }
}
</script>