<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Feedback on the<br> <span class="text-orange">whistleblower protection implementation</span> within your organization</h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
        	<div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-20 points</h6>
                <p class="text-green-dark">If your final score is between 0-20 points, the overall level and accuracy of implementation of WB protection within the organisation is still poor. There is considerable room to improve the management of whistleblower practices with particular regard to the channels available for making whistleblowing reports, disclosures of the reporting contents and investigation procedures. Greater attention should also be paid to establishing protection from retaliation or victimisation and safeguarding the confidentiality of the whistleblower’s identity. The organisation should allocate more efforts and resources to the WB tool, to the management and follow-up of disclosures and to the identification of any possible changes or corrective actions that may be required</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">21-40 points</h6>
                <p class="text-green-dark">If your final score is between 21-40 points, overall, the organisation demonstrates a standard level of commitment in implementing the WB protection regulations. The whistleblower rules and procedures appear to comply with the requirements; however, there are major areas for improvement, particularly with regard to how the internal organisation should be structured in order to encourage reports of wrongdoings and to safeguard the whistleblower's anonymity more effectively. The awareness level of employees with regard to the reporting system put in place is not entirely adequate. The organisation should make more effort to increase the level of confidence in the WB protection system and its benefits among employees. Improvement actions to be taken may include the allocation of skills and resources and the removal of any potential factors that may hinder disclosures. </p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">41-70 points</h6>
                <p class="text-green-dark">If your final score is between 41-70 points, the organisation appears to have taken positive action in implementing whistleblower protection. The rules and procedures are properly applied. Attention is given to the key pillars of the system, i.e. the functioning of the reporting channels, the importance of awareness campaigns and the training of employees and managers. The organisation could take additional actions and make further progress by adopting extra voluntary measures (i.e. in addition to the mandatory measures) to increase the level of trust among its employees and to assess internally the efficiency of its WB protection system. </p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 points</h6>
                <p class="text-green-dark">If your final score is between 71-100 points, this means that the organisation is successfully implementing the whistleblower protection system. All implementation measures are put into place appropriately. Various different reporting channels are available and the organisation checks their level of effectiveness on a regular basis. The organisation also provides all necessary communication and training activities. Furthermore, it has already made efforts to strengthen the internal governance of the WB protection system and to improve its impact. Where necessary, any obstacles to reports are promptly and effectively removed. The organisation's whistleblowing protection is, overall, trusted as an effective anti-corruption measure.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Back to surveys</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Consult results</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
	components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>