<template>    
    <div class="relative flex flex-wrap items-center py-12 shadow-inner px-6 text-center h-screen" v-if="survey"> 
        <div class="body w-full lg:w-3/4 mx-auto mb-10">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">{{ survey.title }}</h1>    
        </div>
        <div class="body w-full lg:w-3/4 mx-auto py-10 bg-white rounded-lg px-4 xl:px-10 mb-10 shadow-lg" v-for="question, index in survey.questions" v-if="isVisible(question)">
            <sup class="text-green-medium text-lg uppercase font-bold">question {{ index + 1 }}</sup>
            <h1 class="text-green-dark text-lg sm:text-2xl mt-6">{{ question.text }}</h1>
            <div class="options mt-4 sm:mt-10">
                <div v-for="option, key in question.options" v-if="options[option.id]" :key="key">
                    <!-- Multiple options -->
                    <label v-if="option.values.length == 0" :id="`l${option.id}`" class="block bg-white sm:bg-white sm:text-teal my-4 p-4 rounded-lg" >
                        <checkIco class="w-5 h-5 mr-2 text-orange" />                 
                        <span class="text-md sm:text-xl text-orange">{{ option.text }}</span>
                        <input v-model="opens[option.id]" v-if="option.open" type="text" class="ml-4 font-bold py-2 px-4 mt-1 text-teal border-none" disabled>
                    </label>
                    <!-- Multiple options with values -->
                    <div v-else class="bg-white my-4 p-4 rounded-lg text-left">
                        <span class="inline-block w-full uppercase text-orange font-bold mb-2">{{ option.text }}</span>
                        <label class="" v-for="value in option.values" class="cursor-pointer inline-block p-0 text-left" :class="value.text.length > 1 ? 'w-full lg:w-1/5' : 'w-1/5 lg:w-2/12'" v-if="options[value.id]">
                            <checkIco class="w-5 h-5 mr-2 text-orange" />    
                            {{ value.text }}                         
                        </label>
                    </div>
                </div>
            </div>                        
        </div>
        <div v-if="!$route.meta.admin" class="actions w-full text-center sm:my-5 pb-10">
            <router-link to="/surveys" class="block w-full sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">{{ $t("Go to Surveys") }}</router-link> 
            <router-link to="/dashboard" class="block w-full sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">{{ $t("Review your results") }}</router-link> 
        </div>
    </div>
</template>
<script>
import checkIco from '@/svg/check.vue'
import { mapGetters, mapState } from 'vuex'

export default {
    components: {checkIco},
    data() {
        return {        
            survey: null,
            options: null,
            opens: null,
            scores: null
        }
    },
    mounted() {        
        if(!this.user.summary) this.$router.push('/dashboard')
        
        this.survey = this.user.summary.survey
        this.options = this.user.summary.entry.options
        this.opens = this.user.summary.entry.opens
        this.scores = this.user.summary.entry.scores
    },
    methods: {
        isVisible(question) {
            let visible = false
            question.options.map(option => {
                if(this.options[option.id]) visible = true
            })
            return visible
        }
    },
    computed: {        
        ...mapState(['user']) 
    }
}
</script>