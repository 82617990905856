<template>
	<div class="body">
		<div class="bg-green-light shadow-inner">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-center">
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6">
		  			<h1 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10">Dobrodošli v orodju projekta Woodie<br><em class="text-orange not-italic">za samooceno uspešnosti ukrepov proti korupciji</em></h1>
		  			<p class="lead text-teal font-bold mt-6">Kako javne uprave po Evropi izvajajo ukrepe za boj proti korupciji pri javnih naročilih?</p>
		  			<p class="lead text-teal font-bold mt-2">Kako so v javni upravi strukturirane in izvajane politike zaščite žvižgačev in odprtih podatkov?</p>
		  			<p class="text-teal mt-10">To spletno orodje za samooceno, ki ga je razvil konzorcij evropskega projekta Woodie, ima za cilj dva ukrepa, ki sta osrednja za boj proti korupciji in varovanje javnega interesa: zaščita žvižgačev in odprti podatki.</p>
		  			<button @click="scrollToAction()" class="bg-orange text-white px-12 py-2 rounded-full uppercase mt-10 focus:outline-none">uporabite orodje</button>
		  		</div>
		  		<div class="hidden lg:block w-1/2 px-24 text-center">
		  			<img src="@/assets/images/hero.png" class="mx-auto">
		  		</div>
		  	</div>
		  </div>
		</div>
​
		<div class="bg-green-dark">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6">
		  			<h2 class="text-white text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">Čemu služi</em> orodje za samooceno Woodie?</h2>
		  			<p class="text-white mt-2">Orodje je bilo zasnovano za pomoč javnim upravam, da:</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Samoocenijo svojo raven ozaveščenosti o zakonodajnem okviru v zvezi z zaščito žvižgačev in odprtih podatkov.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Ugotovijo, ali imajo potreben sistem za popolno izvajanje obveznosti, ki jih nalaga zakonodaja, za doseganje rezultatov in merjenje napredka, doseženega pri izvajanju skozi čas.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Služi kot podlaga za izboljšanje znanja notranjega osebja.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Imajo povratne informacije o področjih, mehanizmih in postopkih, ki jih je treba okrepiti za izboljšanje delovanja in povečati učinek obeh ukrepov na zaposlene, zainteresirane strani in državljane.</p>
		  		</div>
		  	</div>
		  </div>
		</div>
​
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6">
		  			<h2 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">Kdo</em> lahko izpolni orodje za samoocenjevanje projekta Woodie?</h2>
		  		</div>
		  		<div class="w-full px-6 text-left mt-6">
		  			<p class="text-teal">Orodje je namenjeno javnim upravam v evropskih državah, ne glede na velikost. Zbiranje naj izvajajo javni uslužbenci, ki so jim dodeljene naloge in odgovornosti na področju prijaviteljev nepravilnosti (žvižgačev) in/ali odprtih podatkov. Lahko je isti zaposleni, ki pokriva obe področji, lahko pa sta dva ali več zaposlenih.</p>
		  		</div>
		  	</div>
		  </div>
		</div>
​
		<div class="bg-green-medium" id="cta">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start justify-center">
		  		<div class="w-full md:w-2/3 px-6 text-left pt-6">
		  			<h2 class="text-white text-center text-3xl sm:text-5xl font-bold sm:leading-10"><em class="text-green-dark not-italic">Začnite</em> uporabljati orodje</h2>
		  			<p class="text-white mt-6 text-center">Če še vedno nimate računa, lahko nadaljujete z registracijo. Če ga že imate, preprosto kliknite Prijava in začnite uporabljati orodje.</p>
		  			<div class="mt-4 text-center">
		  				<router-link to="/register" class="block w-full mx-auto bg-transparent border text-white text-sm font-bold px-12 py-3 rounded-full uppercase mt-6 md:mt-10 md:inline-block md:w-auto md:mr-10">Registracija</router-link>
		  				<router-link to="/login" class="block w-full mx-auto bg-white border border-white text-sm text-green-dark font-bold px-12 py-3 rounded-full uppercase mt-4 md:mt-10 md:inline-block md:w-auto">vpiši se</router-link>
		  			</div>
		  		</div>
		  	</div>
		  </div>
		</div>
	</div>
</template>
<script>
export default {
  methods: {
  	scrollToAction() {
 		document.getElementById('cta').scrollIntoView({behavior: 'smooth'});	
  	}
  },
}
</script>
