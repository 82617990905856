<template>
	<div class="shadow-inner p-6">
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-8 sm:py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
		  			<h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">Wie funktioniert das <em class="text-orange not-italic">Selbstbewertungs Tool</em>?</h1>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left">
		  			<p class="text-teal">
		  				Der Online-Fragebogen gliedert sich in zwei Pfade: Ein Pfad betrifft den Schutz von Whistleblowern, der zweite Pfad betrifft die Richtlinien in Bezug auf öffentliche Daten
		  			</p>
		  			<p class="text-teal mt-4">
						Jeder Fragebogen besteht aus zwei Teilen:
					</p>
					<p class="text-teal mt-4">
						<em class="text-orange not-italic">-</em> Der erste Teil ist dem jeweiligen nationalen Rechtsrahmen gewidmet, während der zweite Teil die Umsetzung der Maßnahme innerhalb der Verwaltung, die ergriffenen Verfahren und Vorgehensweisen sowie die erzielten Ergebnisse betrifft. Es ist ratsam, diesen letzten Teil alle sechs Monate erneut auszufüllen, da er eng mit der Tätigkeit des Verwaltungsorgans verbunden ist.
					</p>
					<p class="text-teal mt-4">
						<em class="text-orange not-italic">-</em> Der zweite Teil konzentriert sich darauf, wie die Maßnahme (Open Data oder Whistleblowing-Schutz) innerhalb der Organisation umgesetzt wird, welche Verfahren und Maßnahmen ergriffen und welche Ergebnisse erzielt werden. Da dieser Teil stark mit den täglichen Aktivitäten der Organisation verbunden ist, sollte er regelmäßig wiederholt werden, um die Verbesserungen zu überprüfen. 					
		  			</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left">
		  			<p class="text-teal">
		  				Jeder Fragebogen umfasst rund 20 Fragen und kann in ungefähr 15 Minuten ausgefüllt werden. 
		  			<p class="text-teal mt-4">
					</p>
						Benutzer innen konnen:
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> auch nur einen Teil des Fragebogens ausfüllen und den Vorgang zu einem späteren Zeitpunkt wieder aufnehmen.
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> nach dem Ausfüllen ein PDF mit den entsprechenden Ergebnissen erstellen
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> den zeitlichen Verlauf der erzielten Ergebnisse überprüfen. 
					<p class="text-teal mt-4">
					</p>
						Die Selbstbewertung liefert ein Punktesystem und Feedback, welche Bereiche gestärkt werden müssen, um die Leistung und Wirkung der Maßnahme innerhalb der Organisation zu verbessern.
		  			</p>
		  		</div>
		  		<div class="w-full mt-12 sm:mt-24 text-center">
	                <router-link :to="`/surveys`" class="bg-orange text-white px-4 sm:px-12 py-2 rounded-full uppercase my-10  mx-auto focus:outline-none" href="#">
	                    Zu den Umfragen <arrowRightIco class="w-6 text-white -mt-1 ml-3"/>
	                </router-link>
	            </div>
		  	</div>
		  </div>
		</div>				
	</div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
	components: {arrowRightIco},
}
</script>