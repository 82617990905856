<template>
	<div class="shadow-inner p-6">
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-8 sm:py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
		  			<h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">Privacy <em class="text-orange not-italic">Policy</em></h1>
		  		</div>
		  		<div class="w-full lg:w-full px-6 text-left">
		  			<h1 class="text-3x font-bold my-4">Informativa resa ai sensi dell’art. 13 del Regolamento UE n. 2016/679</h1>
		  			<p class="text-teal">		  				
						Gentile Partecipante,<br>
						desideriamo informarLa che il Regolamento UE n. 679/2016 “Regolamento generale sulla protezione dei dati” prevede la tutela delle persone rispetto al trattamento dei dati personali. Secondo la normativa indicata, tale trattamento sarà improntato ai principi di liceità, correttezza e trasparenza, adeguatezza, pertinenza e limitazione, esattezza e aggiornamento, non eccedenza e responsabilizzazione. Pertanto Le forniamo le seguenti informazioni:
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">a) Identità e dati di contatto del Titolare del trattamento</h5>
						Il "Titolare" del trattamento è l’Università degli Studi di Torino - sede in via Verdi 8, 10124 Torino. Centralino +39 011 670.61.11, casella di posta elettronica certificata (Pec): segreteria.rettore@unito.it, legale rappresentante: il Rettore pro tempore
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">b) Dati di contatto del Responsabile della protezione dei dati personali</h5>
						Il Responsabile della protezione dei dati personali – RPD, nella versione anglosassone Data Protection Officer – DPO, può essere contattato al seguente indirizzo mail rpd@unito.it.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">c) Finalità e base giuridica</h5>
						I dati da Lei forniti (dati personali) saranno trattati per esclusive finalità di ricerca scientifica nell’ambito del progetto europeo “Whistleblowing Open Data Impact. An Implementation and Impact Assessment – acronimo WOODIe, finanziato dal Fondo di sicurezza interna – Polizia (Grant Agreement n. 823799) per il biennio 2019-2021. La ricerca è finalizzata alla costruzione di un sistema di valutazione dello stato di attuazione e dell’impatto della normativa in materia di protezione dei whistleblower e di open data nei 7 paesi europei partner, per evidenziare in che modo tali misure contribuiscono a individuare e a contrastare la corruzione negli appalti pubblici.
						Il progetto è a cura del Dipartimento di Giurisprudenza dell’Università degli Studi di Torino. Il Coordinatore del progetto è la prof.ssa Laura Scomparin (laura.scomparin@unito.it).
						A tal fine ai partecipanti sarà chiesto di rispondere alle domande di un questionario online. I dati saranno raccolti in questa prima indagine e successivamente a cadenza semestrale.
						Poter seguire le evoluzioni nella applicazione della sopra citata normativa nel tempo permetterà ai ricercatori di valutare meglio il loro impatto. A questo scopo, si accede al questionario mediante una registrazione. I dati di registrazione saranno noti solamente a Lei, da utilizzare qualora decidesse di ripetere periodicamente il questionario. La base giuridica del trattamento è la Legge n. 168/1989 e s.m.i., 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">d) Modalità del trattamento</h5>
						Il trattamento dei dati personali sarà effettuato con modalità automatizzata. Il titolare del trattamento metterà in atto misure tecniche e organizzative adeguate, quali la pseudonimizzazione, volte ad attuare in modo efficace i principi di protezione dei dati e a tutelare i diritti degli interessati. 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">e) Conferimento dei dati</h5>
						L’adesione alla ricerca è libera e volontaria, la mancata partecipazione non comporta alcuna conseguenza per l’interessato.
						La partecipazione al progetto da parte dell’interessato implica il conferimento dei dati personali richiesti con la registrazione.
						Il mancato conferimento dei dati e la mancata adesione al progetto di ricerca non consentirà la realizzazione del progetto stesso con ricadute ed effetti negativi per il progresso scientifico e tecnologico, per l’evoluzione socio-culturale e per il benessere della collettività.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">f) Destinatari dei dati</h5>
						I dati raccolti non saranno comunicati a soggetti terzi. I dati raccolti attraverso il questionario verranno analizzati dagli statistici del gruppo di lavoro. I risultati potranno essere pubblicati in forma aggregata e mai individuale su riviste scientifiche.
						g)  Trasferimento dati a paese terzo
						I dati raccolti non saranno trasferiti verso un paese con sede al di fuori dell’Unione Europea (c.d. Paese terzo). 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">g) Trasferimento dati a paese terzo</h5>
						I dati raccolti non saranno trasferiti verso un paese con sede al di fuori dell’Unione Europea (c.d. Paese terzo). 
					</p>
					<p class="text-teal">	
						<h5 class="font-bold">h) Periodo di conservazione dei dati</h5>
						I dati personali possono essere conservati per finalità di ricerca scientifica e/o per finalità statistiche anche oltre il periodo necessario per il raggiungimento degli scopi per i quali sono stati raccolti o successivamente trattati, in conformità all’art. 5, § 1 lett. e) e dell’art. 89 del Regolamento. UE in materia di trattamento dei dati personali 2016/679 (GDPR) (I dati saranno conservati per 15 anni).
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">i) Diritti sui dati</h5>
						In ogni momento potrà esercitare i Suoi diritti di cui alla sezione 2, 3 e 4 del capo III del Regolamento UE n. 679/2016 (es. diritti di informazione e accesso, di rettifica e cancellazione, di limitazione e di opposizione al trattamento, di portabilità dei dati personali, etc.) attraverso invio di istanza ad oggetto “diritti privacy” al responsabile interno del trattamento (direzione.giurisprudenza@unito.it), al responsabile dello studio (laura.scomparin@unito.it) e per conoscenza al (rpd@unito.it)
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">j) Reclamo</h5>
						L’interessato ha diritto di proporre reclamo all'autorità di controllo e può rivolgersi all’Autorità Garante per la protezione dei dati personali: https://www.garanteprivacy.it/.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">k) Profilazione</h5>
						Il Titolare non utilizza processi automatizzati finalizzati alla profilazione ai sensi dell’art. 22 Regolamento UE in materia di trattamento dei dati personali 2016/679 (GDPR)			
		  			</p>
		  		</div>		  		
		  	</div>
		  </div>
		</div>				
	</div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
	components: {arrowRightIco},
}
</script>