<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Feedback privind implementarea politicii referitoare la<br><span class="text-orange">Datele deschise</span></h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-25 puncte</h6>
                <p class="text-green-dark">Dacă punctajul dvs. final este între 0-25 de puncte, nivelul general de implementare a politicilor privind datele deschise în cadrul organizației este încă limitat. Există numeroase posibilități de îmbunătățire, în special în ceea ce privește următoarele aspecte cheie: accesul la documente și informații publice, calitatea și cantitatea datelor deschise, accesul real al cetățenilor. Implementarea/urmărirea cerințelor obligatorii lipsește. Este esențial ca organizația să acorde o atenție sporită creșterii eficienței sistemului, precum și îmbunătățirii încrederii generale în capacitatea de a combate corupția. De asemenea, organizația ar trebui să aducă îmbunătățiri semnificative și să întreprindă acțiuni concrete cu privire la următoarele aspecte: alocarea competențelor și resurselor, stabilirea unei structuri de management dedicate și monitorizarea nivelului de transparență atins de DD și utilizarea acestora de către public.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">26-45 puncte</h6>
                <p class="text-green-dark">Dacă punctajul dvs. final este între 26-45 de puncte, organizația demonstrează un nivel standard de angajament în implementarea politicii privind datele deschise preconizate. Politica este pusă în aplicare în conformitate cu cerințele prevăzute de lege; cu toate acestea, există câteva aspecte importante care trebuie îmbunătățite, în special cele referitoare la: prezența personalului intern dedicat (cu atribuții și competențe specifice privind datele deschise) care ar putea contribui la îmbunătățirea accesului la datele publice, volumul/cantitatea datelor deschise oferite, monitorizarea periodică a procesului de implementare și a utilizării efective a acestuia de către cetățeni. Organizația ar trebui să acorde o mai mare atenție creșterii nivelului de încredere în impactul și valoarea datelor deschise ca mijloc de reducere a corupției de către organismele publice. Acțiunile de îmbunătățire care trebuie întreprinse pot include: alocarea de competențe și resurse și evaluarea atât a nivelului de transparență a datelor deschise, cât și a utilizării efective a acestora de către cetățeni și părți interesate.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">46-70 puncte</h6>
                <p class="text-green-dark">Dacă punctajul dvs. final este cuprins între 46-70 de puncte, înseamnă că organizația implementează corect politica privind datele deschise. Regulile și procedurile sunt puse în aplicare în mod corespunzător. Se acordă o atenție adecvată modalităților de accesare a datelor deschise, precum și conținutului lor calitativ și actualizării periodice. Se oferă instruire managerilor interni și angajaților cu privire la modul în care organismul public poate deveni mai transparent și mai responsabil. Există un nivel bun de încredere în creșterea transparenței prin intermediul datelor deschise, care reprezintă un instrument semnificativ pentru combaterea corupției în sectorul public. Progrese ulterioare pot fi obținute concentrându-se îndeaproape pe evaluarea analizei, pentru a înțelege mai profund nivelul real de transparență atins și a utilizării datelor de către cetățeni. </p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 puncte</h6>
                <p class="text-green-dark">Dacă punctajul dvs. final este între 71-100 de puncte, înseamnă că organizația implementează cu succes politica și strategia privind datele deschise. Toate măsurile de implementare sunt puse în aplicare în mod corespunzător. Accesibilitatea și conținutul datelor deschise, împreună cu actualizarea lor, sunt prioritare pentru organizație. Aceasta din urmă oferă, de asemenea, pregătirea necesară managerilor și angajaților care lucrează cu date deschise și instrumentele adecvate pentru a accesa datele. Revizuirea și analiza sistematică a rezultatelor reflectă încrederea organizației în ceea ce privește transparența și datele deschise ca mijloc de combatere a corupției în sectorul public. În cele din urmă, dezvoltarea acțiunilor care vizează sensibilizarea personalului intern și a cetățenilor cu privire la impactul datelor deschise asupra transparenței publice pare consecventă.
</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Înapoi la chestionare</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Consultați rezultatele</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
    components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>