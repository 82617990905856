<template>
    <div class="flex flex-wrap items-center bg-green-light h-screen">
        <div class="w-full lg:w-5/12 px-6 text-center py-6 lg:h-screen bg-white flex flex-wrap items-center">
            <form class="w-full sm:px-20 text-left mt-4">
                <img src="@/assets/images/logo.png" class="h-10 w-auto h-auto max-h-10 mx-auto">
                <div v-if="reset" class="w-full bg-green-light shadow-sm rounded-lg text-green-dark font-bold p-4 mt-12">{{ $t("Password updated successfully, now login with the new credentials") }}</div>
                <label class="block text-green-dark font-bold mt-12">{{ $t("Email") }}</label>
                <input v-model="email" type="email" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1">
                <label class="block text-green-dark font-bold mt-4">{{ $t("Password") }}</label>
                <input v-model="password" type="password" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1">
                <router-link to="/forgot-password" class="inline-block text-green-dark border-orange border-b mt-4">{{ $t("Forgot password?") }}</router-link>
                <button @click.prevent="login" class="relative bg-orange text-white px-12 py-2 rounded-full uppercase mt-10 block w-full focus:outline-none" :disabled="disabled">{{ $t("Login") }}
                    <loaderIco class="h-5 w-5 text-white" v-if="disabled" />
                </button>
                <span v-if="error" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>
            </form>
            <div class="mt-16 lg:mt-24 text-center px-6 pb-4 w-full">
                {{ $t("Don't have an account yet?") }}
                <router-link to="/register" class="text-orange underline">{{ $t("Register here") }}</router-link>
            </div>
        </div>
        <div class="hidden lg:block lg:w-7/12 text-center">
            <img src="@/assets/images/auth.png" class="w-auto h-auto mx-auto px-20">
        </div>
    </div>
</template>
<script>
import loaderIco from '@/svg/loader.vue'

export default {
    components: { loaderIco },
    data() {
        return {
            email: '',
            password: '',
            error: null,
            disabled: false,
            reset: false
        }
    },
    mounted() {
        if (this.$route.query.reset) this.reset = true
    },
    methods: {
        login() {
            const payload = {
                email: this.email,
                password: this.password,
                locale: this.$i18n.locale
            }
            this.disabled = true
            this.axios.post(process.env.VUE_APP_API_URL + '/login', payload)
                .then(response => {
                    this.$store.commit('SET_USER', response.data.data)
                    this.$store.dispatch('loadSurveys')
                    this.$router.push('dashboard')
                })
                .catch(error => {
                    this.disabled = false
                    this.error = error.response.data.message
                })
        }
    },
}
</script>