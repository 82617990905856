<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Feedback del questionario<br> sull’<span class="text-orange">implementazione del Whistleblowing</span></h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
        	<div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-20 punti</h6>
                <p class="text-green-dark">Se il risultato della compilazione del questionario si attesta su un valore che va da 0 a 20 si è virtualmente in una posizione di bassa intenzionalità sulla applicazione della misura del WB. Ci sono ampi margini di miglioramento relativamente ai canali disponibili per il reporting, sull’analisi degli stessi e sulle procedure di presa in carico della denuncia. Non vi è una sufficiente attenzione sulla tutela del WB e sul suo anonimato. Vi è una evidente necessità dell’ente di amplificare gli sforzi e la fiducia nello strumento WB, allocando competenze e risorse, coordinando e verificando gli esiti delle segnalazioni ed eventuali correttivi da mettere in campo.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">21-40 punti</h6>
                <p class="text-green-dark">Se il risultato della compilazione del questionario si attesta su un valore che va da 21 a 40 si è virtualmente in una posizione di media intenzionalità nell’applicazione della misura del WB. Lo strumento è applicato in modo adeguato ma sussistono margini di miglioramento soprattutto sull’organizzazione interna facilitante la segnalazione e il suo anonimato. Non vi è sufficiente sensibilizzazione del personale interno e occorre fare uno sforzo ulteriore teso a testimoniare la fiducia nello strumento, allocando competenze e risorse, verificando se sussistano ostacoli alle eventuali segnalazioni.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">41-70 punti</h6>
                <p class="text-green-dark">Se il risultato della compilazione del questionario si attesta su un valore che va da 41 a 70 si è in una posizione positiva rispetto all’applicazione della misura WB. La norma è applicata nel migliore dei modi, vi è attenzione sui canali di segnalazione, sulla sensibilizzazione e formazione del personale; Può essere fatto uno sforzo ulteriore sul fronte azioni volontarie tese a creare fiducia sullo strumento e a valutarne l’efficacia.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 punti</h6>
                <p class="text-green-dark">Se il risultato della compilazione del questionario si attesta su un valore che va da 71 a 100 si è in una posizione di ente virtuoso rispetto all’applicazione della misura WB. Gli strumenti messi a disposizione sono applicati nel migliore dei modi, vi è attenzione sia sulle modalità di segnalazione che sul loro monitoraggio, sulla sensibilizzazione e formazione del personale. Sono state messe in campo azioni volontarie sul fronte dell’organizzazione interna e della valutazione degli esiti dei percorsi. Vengono attivate, se necessario, misure atte a contrastare impedimenti alle segnalazioni, il monitoraggio sugli esiti denotano una piena fiducia dell’ente sulla misura del WB come reale strumento di anticorruzione. </p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Torna ai questionari</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Consulta risultati</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
	components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>