import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Home from '../pages/Home.vue'
import About from '../pages/About.vue'
import Privacy from '../pages/Privacy.vue'
import Login from '../pages/Login.vue'
import Impersonate from '../pages/Impersonate.vue'
import PasswordEmail from '../pages/PasswordEmail.vue'
import PasswordReset from '../pages/PasswordReset.vue'
import Register from '../pages/Register.vue'
import Dashboard from '../pages/Dashboard.vue'
import DashboardAdmin from '../pages/DashboardAdmin.vue'
import Surveys from '../pages/Surveys.vue'
import Survey from '../pages/Survey.vue'
import FeedbackWB from '../pages/FeedbackWB.vue'
import FeedbackOD from '../pages/FeedbackOD.vue'
import Summary from '../pages/Summary.vue'
import SummaryPDF from '../pages/SummaryPDF.vue'
import SummaryScore from '../pages/SummaryScore.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home, meta: { navbar: true, footer: true } },
  { path: '/instructions', component: About, meta: { navbar: true, footer: true } },
  { path: '/privacy-policy', component: Privacy, meta: { navbar: true, footer: true } },
  { path: '/register', component: Register, meta: { navbar: false, footer: false } },
  { path: '/login', component: Login, meta: { navbar: false, footer: false } },  
  { path: '/forgot-password', component: PasswordEmail, meta: { navbar: false, footer: false } },
  { path: '/reset-password', component: PasswordReset, meta: { navbar: false, footer: false }, name: 'reset-password' },
  { path: '/dashboard', component: Dashboard, meta: { navbar: true, footer: false, auth: true } },  
  { path: '/surveys', component: Surveys, meta: { navbar: true, footer: false, auth: true } },
  { path: '/surveys/:id', component: Survey, meta: { navbar: true, footer: false, auth: true } },
  { path: '/feedback/wb', component: FeedbackWB, meta: { navbar: true, footer: false, auth: true } },
  { path: '/feedback/od', component: FeedbackOD, meta: { navbar: true, footer: false, auth: true } },
  { path: '/summary', component: Summary, meta: { navbar: true, footer: false, auth: true } },
  { path: '/summary-pdf', component: SummaryPDF, meta: { navbar: true, footer: false, auth: true } },
  { path: '/summary-score', component: SummaryScore, meta: { navbar: true, footer: false, auth: true } },
  /* Admin views */
  { path: '/admin/impersonate/:id/:code', component: Impersonate, meta: { navbar: false, footer: false } },
  { path: '/admin/dashboard', component: DashboardAdmin, meta: { navbar: false, footer: false, auth: true, admin: true } },
  { path: '/admin/summary', component: Summary, meta: { navbar: false, footer: false, auth: true, admin: true } },
  { path: '/admin/summary-pdf', component: SummaryPDF, meta: { navbar: false, footer: false, auth: true, admin: true } },
  { path: '/admin/summary-score', component: SummaryScore, meta: { navbar: false, footer: false, auth: true, admin: true } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {    
    // redirect to homepage when reload browser page from a form view
    if ( to.meta.auth && !store.state.token ) {
        next('/login');
    } 
    else {
        next();
    }    
});

export default router
