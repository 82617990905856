<template>
	<div>
		<PrivacyEN v-if="$i18n.locale == 'en'"/>
		<PrivacyIT v-if="$i18n.locale == 'it'"/>
		<PrivacyFR v-if="$i18n.locale == 'fr'"/>
		<PrivacyDE v-if="$i18n.locale == 'de'"/>
		<PrivacySLO v-if="$i18n.locale == 'slo'"/>
		<PrivacyRU v-if="$i18n.locale == 'ru'"/>
	</div>
</template>
<script>
import PrivacyEN from './en/Privacy.vue'
import PrivacyIT from './it/Privacy.vue'
import PrivacyFR from './fr/Privacy.vue'
import PrivacyDE from './de/Privacy.vue'
import PrivacySLO from './slo/Privacy.vue'
import PrivacyRU from './ru/Privacy.vue'

export default {
  components: { PrivacyEN, PrivacyIT, PrivacyFR, PrivacyDE, PrivacyRU }
}
</script>