<template>
	<div>
		<AboutEN v-if="$i18n.locale == 'en'"/>
		<AboutIT v-if="$i18n.locale == 'it'"/>
		<AboutFR v-if="$i18n.locale == 'fr'"/>
		<AboutDE v-if="$i18n.locale == 'de'"/>
		<AboutSLO v-if="$i18n.locale == 'slo'"/>
		<AboutRU v-if="$i18n.locale == 'ru'"/>
	</div>
</template>
<script>
import AboutEN from './en/About.vue'
import AboutIT from './it/About.vue'
import AboutFR from './fr/About.vue'
import AboutDE from './de/About.vue'
import AboutSLO from './slo/About.vue'
import AboutRU from './ru/About.vue'

export default {
  components: { AboutEN, AboutIT, AboutFR, AboutDE, AboutSLO, AboutRU }
}
</script>