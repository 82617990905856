<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Feedback zur Umsetzung der<br> <span class="text-orange">Open-Data-Richtlinien</span></h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
        	<div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-25 punkten</h6>
                <p class="text-green-dark">Wenn Ihr Endergebnis zwischen 0-25 Punkten liegt, scheint der Gesamtgrad der Umsetzung der Open-Date-Richtlinie innerhalb der Organisation immer noch mangelhaft bzw. begrenzt zu sein. Es besteht erheblicher Spielraum für Verbesserungen, insbesondere im Hinblick auf folgende Schlüsselmerkmale: Zugang zu öffentlichen Dokumenten und Informationen, Qualität und Quantität der offenen Daten, tatsächlicher Zugang der Bürger. Es mangelt an der Umsetzung/Nachbetreuung verbindlicher Vorgaben. Es ist von entscheidender Bedeutung, dass die Organisation der Effizienzsteigerung des Systems sowie der Verbesserung des allgemeinen Vertrauens in seine positiven Auswirkungen auf die Korruptionsbekämpfung mehr Aufmerksamkeit schenkt. Die Organisation sollte auch grundlegende Verbesserungen vornehmen und konkrete Maßnahmen zu folgenden Aspekten ergreifen: Zuteilung von Kompetenzen und Ressourcen, Einrichtung einer speziellen Verwaltungsstruktur und Überwachung des von den OD erreichten Grades an Transparenz und deren Nutzung durch die Öffentlichkeit.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">26-45 punkten</h6>
                <p class="text-green-dark">Wenn Ihr Endergebnis zwischen 26-45 Punkten liegt, zeigt die Organisation ein Standardniveau an Engagement bei der Umsetzung der Open-Data-Richtlinie. Die Richtlinie wird im Einklang mit den gesetzlich vorgesehenen Anforderungen umgesetzt; es gibt jedoch einige wichtige Bereiche, in denen Verbesserungen erforderlich sind, insbesondere in Bezug auf: das Vorhandensein eines engagierten internen Personals (mit spezifischen Aufgaben und Fähigkeiten in Bezug auf offene Daten), das dazu beitragen könnte, den Zugang zu öffentlichen Daten zu verbessern, die Menge an freigegebener offenen Daten, die regelmäßige Überwachung des Umsetzungsprozesses und ihre tatsächliche Nutzung durch die Bürger. Die Organisation sollte der Erhöhung des Vertrauens in die Wirkung und den Wert offener Daten als Mittel zur Verringerung der Korruption in öffentlichen Einrichtungen große Aufmerksamkeit schenken. Zu den zu ergreifenden Verbesserungsmaßnahmen können gehören: Zuteilung von Kompetenzen und Ressourcen und die Bewertung sowohl des Transparenzgrades offener Daten als auch ihrer tatsächlichen Nutzung durch Bürger und Interessenvertreter.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">46-70 punkten</h6>
                <p class="text-green-dark">Wenn Ihr Endergebnis zwischen 46-70 Punkten liegt, bedeutet dies, dass die Organisation die Open-Data-Richtlinie gut umsetzt. Die Regeln und Verfahren werden ordnungsgemäß umgesetzt. Angemessene Aufmerksamkeit wird den Möglichkeiten des Zugangs zu offenen Daten sowie deren qualitativen Inhalten und der regelmäßigen Aktualisierung gewidmet. Es werden auch Schulungen für Manager und Mitarbeiter angeboten, um die öffentliche Stelle transparenter und rechenschaftspflichtig zu machen. Es besteht ein gutes Maß an Vertrauen in die Erhöhung der Transparenz durch offene Daten, die ein sinnvolles Instrument zur Bekämpfung der Korruption im öffentlichen Sektor darstellen. Weitere Fortschritte können erzielt werden, wenn man sich eng auf die Bewertung konzentriert, um ein tieferes Verständnis des tatsächlich erreichten Grades an Transparenz und der Datennutzung durch die Bürger zu erreichen.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 punkten</h6>
                <p class="text-green-dark">Wenn Ihr Endergebnis zwischen 71-100 Punkten liegt, bedeutet dies, dass die Organisation die Open-Data-Richtlinie und -Strategie erfolgreich umsetzt. Alle Umsetzungsmaßnahmen werden ordnungsgemäß durchgeführt. Die Zugänglichkeit und der Inhalt der offenen Daten sowie deren Aktualisierung haben für die Organisation hohe Priorität. Letztere bietet auch die notwendige Ausbildung für Manager und Mitarbeiter, die mit offenen Daten arbeiten, sowie die geeigneten Instrumente für den Zugang zu den Daten. Eine systematische Überprüfung und Analyse der Ergebnisse spiegeln das Vertrauen der Organisation in Bezug auf Transparenz und offene Daten als Mittel zur Bekämpfung der Korruption im öffentlichen Sektor wider. Besonders konsequent erscheint schließlich die Entwicklung von Maßnahmen zur Sensibilisierung der internen Mitarbeiter und Bürger für die Auswirkungen offener Daten auf die öffentliche Transparenz.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Zurück zu den Fragebögen</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Ergebnisse konsultieren</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
	components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>