<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Feedback privind implementarea politicii <br> <span class="text-orange">referitoare la AI</span></h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
        	<div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-20 puncte</h6>
                <p class="text-green-dark">Dacă punctajul dvs. final este între 0-20 de puncte, nivelul general și precizia implementării protecției AI în cadrul organizației sunt încă limitate. Există numeroase posibilități de îmbunătățire a gestionării practicilor de avertizare de integritate, în special în ceea ce privește canalele disponibile pentru realizarea rapoartelor de denunțare, divulgarea conținutului avertizării și procedurile de investigație. O atenție sporită ar trebui, de asemenea, să fie acordată protecției împotriva represaliilor sau victimizării și protejării confidențialității identității avertizorului de integritate. Organizația ar trebui să aloce mai multe eforturi și resurse instrumentului AI, gestionării și urmăririi sesizărilor și identificării eventualelor modificări sau acțiuni corective care ar putea fi necesare.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">21-40 puncte</h6>
                <p class="text-green-dark">Dacă punctajul dvs. final este cuprins între 21-40 de puncte, în ansamblu organizația demonstrează un nivel standard de angajare în implementarea reglementărilor de protecție a AI. Regulile și procedurile avertizării de integritate par să respecte cerințele; cu toate acestea există numeroase aspecte ce trebuie îmbunătățite, în special în ceea ce privește modul în care ar trebui structurată organizarea internă pentru a încuraja raportările cu privire la acțiunile greșite și pentru a proteja mai eficient anonimatul avertizorilor. Nivelul de conștientizare al angajaților cu privire la sistemul de raportare pus în aplicare nu este în întregime adecvat. Organizația ar trebui să depună mai multe eforturi pentru a crește nivelul de încredere în sistemul de protecție a AI și beneficiile sale în rândul angajaților. Acțiunile de îmbunătățire care trebuie întreprinse pot include alocarea de competențe și resurse și eliminarea oricăror factori potențiali care pot împiedica sesizările.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">41-70 puncte</h6>
                <p class="text-green-dark">Dacă punctajul dvs. final este între 41-70 de puncte, organizația pare să fi luat măsuri pozitive de implementare a protecției avertizorilor. Regulile și procedurile sunt aplicate corespunzător. Se acordă atenție pilonilor cheie ai sistemului, respectiv funcționarea canalelor de raportare, importanța campaniilor de sensibilizare și formarea angajaților și managerilor. Organizația ar putea întreprinde acțiuni suplimentare și ar putea face progrese prin adoptarea unor măsuri voluntare suplimentare (în plus față de măsurile obligatorii) pentru a crește nivelul de încredere în rândul angajaților săi și pentru a evalua intern eficiența sistemului său de protecție a AI.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 puncte</h6>
                <p class="text-green-dark">Dacă punctajul dvs. final este între 71-100 de puncte, înseamnă că organizația implementează cu succes sistemul de protecție a avertizorilor de integritate. Toate măsurile de implementare sunt puse în aplicare în mod corespunzător. Sunt disponibile diferite canale de raportare, iar organizația își verifică în mod regulat nivelul de eficiență. Organizația asigură, de asemenea, toate activitățile de comunicare și instruire necesare. Totodată, a depus deja eforturi pentru a consolida guvernanța internă a sistemului de protecție a AI și pentru a îmbunătăți impactul acestuia. Dacă este necesar, orice obstacole în calea rapoartelor sunt eliminate rapid și eficient. Protecția avertizorilor de integritate ai organizației este, în general, considerată drept o măsură eficientă de combatere a corupției.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Înapoi la chestionare</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Consultați rezultatele</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
	components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>