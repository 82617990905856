<template>
	<div class="shadow-inner p-6">
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-8 sm:py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
		  			<h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">Cum funcționează <em class="text-orange not-italic">instrumentul</em> de <em class="text-orange not-italic">autoevaluare</em>?</h1>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left">
		  			<p class="text-teal">
		  				Instrumentul online este organizat pe două direcții: una se referă la protecția avertizorilor în interes public și cealaltă la politicile în domeniul datelor deschise.
		  			</p>
		  			<p class="text-teal mt-4">
						Fiecare sondaj este alcătuit din două părți: (date deschise sau protecția avertizorilor în interes public)
					</p>
					<p class="text-teal mt-4">
						<em class="text-orange not-italic">-</em> Prima este dedicată cadrului legislativ pe această temă, în timp ce a doua se concentrează pe implementarea măsurii în cadrul administrației, pe procedurile și acțiunile adoptate, precum și pe rezultatele obținute. Vă recomandăm să repetați completarea acestei ultime părți la fiecare șase luni, deoarece este strâns legată de activitatea administrației.
					</p>
					<p class="text-teal mt-4">
						<em class="text-orange not-italic">-</em> A doua parte se concentrează asupra modului în care măsura (date deschise sau protecție împotriva denunțărilor) este implementată în cadrul organizației, procedurile și acțiunile adoptate și rezultatele obținute. Deoarece această parte este strâns legată de activitățile de zi cu zi ale organizației, ar trebui repetată periodic pentru a verifica îmbunătățirile. 					
		  			</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left">
		  			<p class="text-teal">
		  				Fiecare chestionar conține aproximativ 20 de întrebări și completarea sa durează aproximativ 15 minute 
		  			<p class="text-teal mt-4">
					</p>
						Sistemul permite:
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> completarea unei părți din chestionar și reluarea ulterioară a completării
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> crearea unui pdf la sfârșitul completării, cu rezultatele obținute
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> verificarea evoluției în timp a punctajelor obținute 
					<p class="text-teal mt-4">
					</p>
						Obținerea unui scurt feedback cu privire la domeniile care necesită mai multă atenție pentru îmbunătățirea impactului măsurilor în administrație
		  			</p>
		  		</div>
		  		<div class="w-full mt-12 sm:mt-24 text-center">
	                <router-link :to="`/surveys`" class="bg-orange text-white px-4 sm:px-12 py-2 rounded-full uppercase my-10  mx-auto focus:outline-none" href="#">
	                    Mergeți la sondaje <arrowRightIco class="w-6 text-white -mt-1 ml-3"/>
	                </router-link>
	            </div>
		  	</div>
		  </div>
		</div>				
	</div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
	components: {arrowRightIco},
}
</script>