<template>
    <div class="flex flex-wrap items-center bg-green-light h-screen">
        <div class="w-full lg:w-5/12 px-6 text-center py-6 lg:pb-16 bg-white flex flex-wrap items-center">
            <form class="w-full sm:px-20 text-left mt-4">
                <img src="@/assets/images/logo.png" class="h-10 w-auto h-auto max-h-10 mx-auto">
                
                <label class="block text-green-dark font-bold mt-12">{{ $t("Name") }}</label>
                <input v-model="name" type="text" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1 focus:outline-none">
                <span v-if="errors.name" v-for="error in errors.name" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>
                
                <label class="block text-green-dark font-bold mt-4">{{ $t("Email") }}</label>
                <input v-model="email" type="email" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1 focus:outline-none">
                <span v-if="errors.email" v-for="error in errors.email" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>
                
                <label class="block text-green-dark font-bold mt-4">{{ $t('register.office') }}</label>
                <input v-model="office" type="text" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1 focus:outline-none">
                <span v-if="errors.office" v-for="error in errors.office" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>

                <label class="block text-green-dark font-bold mt-4">{{ $t('register.office_type.label') }}</label>
                <select v-model="office_type" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1 focus:outline-none">
                    <option v-for="option, key in $t('register.office_type.options')" :value="key">{{ option }}</option>
                </select>
                <span v-if="errors.office_type" v-for="error in errors.office_type" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>

                <label class="block text-green-dark font-bold mt-4">{{ $t('register.office_dimension.label') }}</label>
                <select v-model="office_dimension" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1 focus:outline-none">
                    <option v-for="option, key in $t('register.office_dimension.options')" :value="key">{{ option }}</option>
                </select>
                <span v-if="errors.office_type" v-for="error in errors.office_type" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>

                <label class="block text-green-dark font-bold mt-4">{{ $t('register.office_role.label') }}</label>
                <select v-model="office_role" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1 focus:outline-none">
                    <option v-for="option, key in $t('register.office_role.options')" :value="key">{{ option }}</option>
                </select>
                <span v-if="errors.office_role" v-for="error in errors.office_role" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>

                <label class="block text-green-dark font-bold mt-4">{{ $t('register.office_seniority.label') }}</label>
                <select v-model="office_seniority" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1 focus:outline-none">
                    <option v-for="option, key in $t('register.office_seniority.options')" :value="key">{{ option }}</option>
                </select>
                <span v-if="errors.office_role" v-for="error in errors.office_role" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>


                <label class="block text-green-dark font-bold mt-4">{{ $t("New Password") }}</label>
                <input v-model="password" type="password" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1 focus:outline-none">
                <span v-if="errors.password" v-for="error in errors.password" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>
                
                <label class="block text-green-dark font-bold mt-4">{{ $t("Password Confirmation") }}</label>
                <input v-model="password_confirmation" type="password" class="w-full block border border-gray-dark rounded-full bg-gray-light py-2 px-4 mt-1 focus:outline-none">
                
                <button @click.prevent="register" class="relative bg-orange text-white px-12 py-2 rounded-full uppercase mt-10 block w-full focus:outline-none" :disabled="disabled">{{ $t("Register") }}
                    <loaderIco class="h-5 w-5 text-white" v-if="disabled" />
                </button>

                <label class="block text-green-dark mt-4">
                    <input type="checkbox" v-model="tos" class="mr-1">
                    <i18n path="term" tag="label" for="tos">
                        <router-link to="/privacy-policy" class="font-bold underline" target="_blank">{{ $t('tos') }}</router-link>
                    </i18n>
                </label>
                <span v-if="errors.tos" v-for="error in errors.tos" class="block text-red ml-4 mt-2 font-bold">{{ error }}</span>
            </form>
            <div class="mt-16 lg:mt-16 text-center px-6 pb-4 w-full">
               {{ $t("Already have a Woodie account?") }}
                <router-link to="/login" class="text-orange underline">{{ $t("Go to Login") }}</router-link>
            </div>
        </div>
        <div class="hidden lg:block lg:w-7/12 text-center">
            <img src="@/assets/images/auth.png" class="w-auto h-auto mx-auto px-20">
        </div>
    </div>
</template>
<script>
import loaderIco from '@/svg/loader.vue'

export default {
    components: { loaderIco },
    data() {
        return {
            name: '',
            email: '',
            password: '',
            office: '',
            office_type: '',
            office_dimension: '',
            office_role: '',
            office_seniority: '',
            password_confirmation: '',
            tos: null,
            errors: {
                name: [],
                email: [],
                password: [],
                tos: []
            },
            disabled: false
        }
    },
    computed: {

    },
    methods: {
        register() {
            const payload = {
                name: this.name,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                office: this.office,
                office_type: this.office_type,
                office_dimension: this.office_dimension,
                office_role: this.office_role,
                office_seniority: this.office_seniority,
                tos: this.tos,
                locale: this.$i18n.locale
            }
            this.disabled = true
            this.axios.post(process.env.VUE_APP_API_URL + '/register', payload)
                .then(response => {
                    this.disabled = false
                    this.$store.commit('SET_USER', response.data.data)
                    this.$store.dispatch('loadSurveys')
                    this.$router.push('dashboard')
                })
                .catch(error => {
                    this.disabled = false
                    if (error.response && error.response.status === 422)
                        this.errors = error.response.data.data
                })
        }
    },
}
</script>