<template>
    <div class="py-12 shadow-inner px-6 text-center">
        <div class="heading w-full">
            <h1 class="text-green-dark text-3xl sm:text-5xl font-bold sm:leading-10">{{ $t("Surveys") }}</h1>
            <p class="text-green-dark mt-6">{{ $t("Click on one of the boxes to start the self-assessment") }}.</p>
        </div>
        <div class="grid lg:grid-cols-2 gap-x-10 mt-10 w-full sm:w-1/2 mx-auto" v-if="surveys">
            <div v-for="survey in surveys" :key="survey.id" class="content text-center mt-10 m-auto">
                <router-link :to="`/surveys/${survey.id}`" class="bg-white rounded-lg px-4 xl:px-10 py-6 block w-full shadow-lg" href="#">
                    <sup class="text-green-medium uppercase font-bold">{{ $t("go to") }}</sup>
            	   <h4 class="text-green-dark font-black">{{ survey.title }}</h4>
                   <arrowRightIco class="w-6 text-orange mt-2"/>
                </router-link>
            </div>            
        </div>
        <div v-if="!surveys.length" class="w-full mt-6 text-center">
            <h5 class="text-xl font-bold sm:leading-10">{{ $t("No surveys available for the current language") }}</h5>
        </div>
    </div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
import { mapGetters } from 'vuex'

export default {
	components: {arrowRightIco},
    data() {
        return {
            error: false
        }
    },
    mounted() {
        this.$store.dispatch('loadSurveys')
    },
    computed: {
        ...mapGetters({
            surveys: 'getSurveys'
        })
    },
    methods: {}
}
</script>