<template>
	<div>
		<FeedbackEN v-if="$i18n.locale == 'en'"/>
		<FeedbackIT v-if="$i18n.locale == 'it'"/>
		<FeedbackFR v-if="$i18n.locale == 'fr'"/>
		<FeedbackDE v-if="$i18n.locale == 'de'"/>
		<FeedbackSLO v-if="$i18n.locale == 'slo'"/>
		<FeedbackRU v-if="$i18n.locale == 'ru'"/>
	</div>
</template>
<script>
import FeedbackEN from './en/FeedbackOD.vue'
import FeedbackIT from './it/FeedbackOD.vue'
import FeedbackFR from './fr/FeedbackOD.vue'
import FeedbackDE from './de/FeedbackOD.vue'
import FeedbackSLO from './slo/FeedbackOD.vue'
import FeedbackRU from './ru/FeedbackOD.vue'

export default {
  components: { FeedbackEN, FeedbackIT, FeedbackFR, FeedbackDE, FeedbackSLO, FeedbackRU }
}
</script>