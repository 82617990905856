<template>
	<div class="shadow-inner p-6">
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-8 sm:py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
		  			<h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">Privacy <em class="text-orange not-italic">Policy</em></h1>
		  		</div>
		  		<div class="w-full lg:w-full px-6 text-left">
		  			<h1 class="text-3x font-bold my-4">Datenschutzerklärung gemäß Art. 13 der Verordnung (EU) Nr. 2016/679</h1>
		  			<p class="text-teal">		  				
						Sehr geehrte/r Teilnehmer/in,<br>
						wir möchten Sie darüber informieren, dass die Verordnung (EU) Nr. 679/2016 „Datenschutz-Grundverordnung“ den Schutz von Personen bei der Verarbeitung ihrer personenbezogenen Daten regelt. Gemäß den angegebenen Rechtsvorschriften unterliegt diese Verarbeitung den Grundsätzen der Rechtmäßigkeit, Korrektheit und Transparenz, Angemessenheit, Relevanz und Einschränkung, Genauigkeit und Aktualisierung, Nichtüberschreitung und Rechenschaftspflicht. Aus diesem Anlass möchten wir sie informieren:
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">a) Identität und Daten des für die Verarbeitung Verantwortlichen</h5>
						Der „für die Verarbeitung Verantwortliche“ ist die Universität Turin mit Sitz in der Via Verdi 8, 10124 Turin. Kontakt: +39 011 670.61.11, E-Mail-Adresse (PEC): segreteria.rettore@unito.it, rechtlicher Vertreter: der amtierende Rektor.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">b) Kontaktdaten des Datenschutzbeauftragten</h5>
						Der Datenschutzbeauftragte – DSB, in der englischen Version Data Protection Officer – DPO, kann unter folgender E-Mail-Adresse kontaktiert werden: rpd@unito.it.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">c) Zwecke und Rechtsgrundlage</h5>
						Die von Ihnen bereitgestellten Daten (personenbezogenen Daten) werden ausschließlich für wissenschaftliche Forschungszwecke im Rahmen des europäischen Projekts „Whistleblowing Open Data Impact. An Implementation and Impact Assessment“ – kurz WOODIe, finanziert aus dem Fond für interne Sicherheit – Polizei“ (Grant Agreement Nr. 823799) für den Zeitraum 2019-2021 verarbeitet. Diese Forschungsarbeit zielt darauf ab, ein System zur Bewertung des Umsetzungsgrades sowie der Auswirkungen der Rechtsvorschriften in Bezug auf den Schutz von Whistleblowern und Open Data in den sieben europäischen Partnerländern aufzubauen, um herauszufinden, wie diese Maßnahmen zur Ermittlung und Bekämpfung der Korruption im öffentlichen Beschaffungswesen beitragen.
						Das Projekt wird von der Fakultät für Rechtswissenschaften der Universität Turin geführt. Projektkoordinatorin ist Frau Prof. Laura Scomparin (laura.scomparin@unito.it).
						Zu diesem Zweck werden die Teilnehmer gebeten, einen Online-Fragebogen zu beantworten. Die Daten werden in dieser ersten Umfrage und anschließend alle sechs Monate erhoben.
						Indem die Forscher die Möglichkeit bekommen, die Entwicklung der Anwendung der oben genannten Rechtsvorschriften im Laufe der Zeit zu verfolgen, können sie deren Auswirkungen besser beurteilen. Zu diesem Zweck kann auf den Fragebogen nach Registrierung zugegriffen werden. Die Registrierungsdaten sind nur Ihnen bekannt . Diese können von Ihnen verwendet werden, wenn Sie sich dafür entscheiden sollten, den Fragebogen regelmäßig zu wiederholen.
						Rechtsgrundlage der Datenverarbeitung ist das italienische Gesetz Nr. 168/1989 i.d.g.F. 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">d) Methoden der Datenverarbeitung</h5>
						Die Verarbeitung der personenbezogenen Daten erfolgt mit automatisierten Verfahren. Der für die Verarbeitung Verantwortliche wird geeignete technische und organisatorische Maßnahmen, wie z.B. die Pseudonymisierung ergreifen, um die Grundsätze des Datenschutzes wirksam umzusetzen und die Rechte der betroffenen Personen zu schützen. 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">e) Bereitstellung der Daten</h5>
						Die Teilnahme am Forschungsprojekt ist kostenlos und freiwillig. Eine mangelnde Teilnahme hat keine Konsequenzen für die betroffene Person.
						Die Teilnahme der betroffenen Person am Projekt setzt die Bereitstellung der bei der Registrierung angeforderten personenbezogenen Daten voraus.
						Eine mangelnde Bereitstellung der Daten sowie eine mangelnde Teilnahme am Forschungsprojekt verhindert die Durchführung des Projekts selbst, mit negativen Auswirkungen auf den wissenschaftlichen und technologischen Fortschritt, die soziokulturelle Entwicklung und das Wohlergehen der Gemeinschaft.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">f) Empfänger der Daten</h5>
						Die erhobenen Daten werden Dritten nicht offengelegt. Die mittels Fragebogen erhobenen Daten werden von den Statistikern des Projekts analysiert. Die Ergebnisse können in aggregierter, niemals jedoch in individueller Form in wissenschaftlichen Fachzeitschriften veröffentlicht werden.
						g)  Trasferimento dati a paese terzo
						I dati raccolti non saranno trasferiti verso un paese con sede al di fuori dell’Unione Europea (c.d. Paese terzo). 
					</p>
						<h5 class="font-bold">g) Übermittlung der Daten an Drittländer</h5>
						Die erhobenen Daten werden nicht an Länder außerhalb der Europäischen Union übermittelt (s.g. Drittländer).
					</p>
					<p class="text-teal">	
						<h5 class="font-bold">h) Dauer der Datenspeicherung</h5>
						Die personenbezogenen Daten können für wissenschaftliche Forschungszwecke und/oder statistische Zwecke auch über den Zeitraum hinaus gespeichert werden, der zur Erfüllung jener Zwecke erforderlich ist, für die sie erhoben oder anschließend verarbeitet wurden, und zwar in Einklang mit Art. 5, § 1, Lit. e), und Art. 89 der Verordnung (EU) zur Verarbeitung personenbezogener Daten 2016/679 (DSGVO). (Die Daten werden für 15 Jahre gespeichert).
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">i) Rechte an den Daten</h5>
						Sie können Ihre Rechte, die unter Abschnitt 2, 3 und 4 des Kapitels III der EU-Verordnung Nr. 679/2016 angeführt werden (z.B. Recht auf Information und Auskunft, Recht auf Berichtigung und Löschung, Recht auf Einschränkung der Verarbeitung und Widerspruch gegen die Verarbeitung, Recht auf Datenübertragbarkeit usw.), durch Senden eines Antrags mit dem Betreff „Datenschutzrechte“ an den internen Datenprozessor, an den/die Leiter/in der Fakultät für Rechtswissenschaften (E-Mail-Adresse einfügen) und an die Studienleiterin (laura.scomparin@unito.it) sowie an rpd@unito.it, jederzeit ausüben.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">j) Beschwerdeverfahren</h5>
						Die betroffene Person hat das Recht, Beschwerde bei der zusändigen Aufsichtsbehörde einzulegen. Sie kann sich dazu an die Datenschutzbehörde wenden: https://www.garanteprivacy.it/.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">k) Profiling</h5>
						Der Verantwortliche verwendet keine automatisierten Profiling-Verfahren gemäß Art. 22 der EU-Verordnung zur Verarbeitung personenbezogener Daten 2016/679 (DSGVO).			
		  			</p>
		  		</div>		  		
		  	</div>
		  </div>
		</div>				
	</div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
	components: {arrowRightIco},
}
</script>