<template>
    <div class="relative flex flex-wrap items-center py-12 shadow-inner px-6 text-center h-screen" v-if="survey">
        <div class="body w-full lg:w-3/4 mx-auto">
            <div v-if="survey.feedback == 'tot'">
                <Score :survey="survey" :options="options" :scores="scores"></Score>
            </div>
            <div v-else>
                <ScoreCategory :survey="survey" :options="options" :scores="scores"></ScoreCategory>
            </div>
        </div>
    </div>
</template>
<script>
import ScoreCategory from '@/components/ScoreCategory.vue'
import Score from '@/components/Score.vue'
import { mapGetters, mapState } from 'vuex'

export default {
	components: {ScoreCategory,Score},
    data() {
        return {
            survey: null,
            options: null,
            opens: null,
            scores: null
        }
    },
    mounted() {
        if (!this.user.summary) this.$router.push('/dashboard')

        this.survey = this.user.summary.survey
        this.options = this.user.summary.entry.options
        this.opens = this.user.summary.entry.opens
        this.scores = this.user.summary.entry.scores
    },
    computed: {
        ...mapState(['user'])
    }
}
</script>