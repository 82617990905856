<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Feedback del questionario<br> sull’<span class="text-orange">implementazione del OpenData</span></h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
        	<div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-25 punti</h6>
                <p class="text-green-dark">Se il risultato della compilazione del questionario si attesta su un valore che va da 0 a 25 si è virtualmente in una posizione di bassa intenzionalità sulla creazione di una struttura Open Data. Ci sono ampi margini di  miglioramento sull’accesso ai documenti, sui contenuti degli open data, sul monitoraggio della corretta applicazione della norma e sull’accesso reale dei cittadini. Vi è una evidente necessità dell’ente di rafforzare gli sforzi e la fiducia nello strumento open data, allocando competenze e risorse, coordinando e verificando il livello reale di trasparenza dei dati e del loro utilizzo da parte dei cittadini.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">26-45 punti</h6>
                <p class="text-green-dark">Se il risultato della compilazione del questionario si attesta su un valore che va da 26 a 45 si è virtualmente in una posizione di media intenzionalità per la creazione di una struttura Open Data. La norma è applicata in modo adeguato ma sussistono margini di miglioramento soprattutto sull’ingaggio delle risorse umane interne che faciliterebbero l’accesso ai documenti, la quantità dei contenuti open data, il monitoraggio della corretta applicazione della norma e dell’accesso reale dei cittadini. Vi è una necessità dell’ente di fare uno sforzo ulteriore teso a testimoniare la fiducia nello strumento dell’open data, allocando competenze e risorse, verificando il livello reale di trasparenza dei dati e del loro utilizzo da parte dei cittadini.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">46-70 punti</h6>
                <p class="text-green-dark">Se il risultato della compilazione del questionario si attesta su un valore che va da 46 a 70 si è virtualmente in una posizione positiva rispetto all’applicazione della misura Open Data. La norma è applicata nel migliore dei modi, vi è attenzione sia sulle modalità di accesso ai dati che sui contenuti open data, sui loro aggiornamenti; si nota una attenzione sulla formazione delle risorse impiegate per rendere il proprio ente trasparente. Vi è fiducia che la trasparenza, l’accesso ai dati possa essere un reale strumento di anticorruzione. Può essere fatto uno sforzo ulteriore sul fronte del monitoraggio degli accessi ai fini di verificare il livello reale di trasparenza dei dati e del loro utilizzo.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 punti</h6>
                <p class="text-green-dark">Se il risultato della compilazione del questionario si attesta su un valore che va da 71 a 100 si è in una posizione di ente virtuoso rispetto all’applicazione della misura Open Data. La norma è applicata nel migliore dei modi, vi è attenzione sia sulle modalità di accesso ai dati che sui contenuti open data, sui loro aggiornamenti; buona formazione delle risorse impiegate sulla trasparenza del proprio ente, strumenti adeguati di accesso ai dati. Il monitoraggio sugli esiti denotano una piena fiducia dell’ente sulla trasparenza e sull’accesso ai dati come reale strumento di anticorruzione. Vi è consistenza di attività messe in campo per sensibilizzare le risorse umane interne ed i cittadini sulla trasparenza dei dati.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Torna ai questionari</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Consulta risultati</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
	components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>