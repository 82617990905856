<template>   
     <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"        
        :manual-pagination="false"
        :paginate-elements-by-height="1200"
        :html-to-pdf-options="config"
        pdf-format="a4"
        ref="html2Pdf"
    >
        <section slot="pdf-content">   
            <div class="text-center">
                <img src="@/assets/images/logo.png" class="h-10 w-auto mx-auto h-auto max-h-10 mb-5">
            </div>
            <div v-if="survey"> 
                <div class="body w-full mx-auto mb-2">
                    <h1 class="text-green-dark font-bold text-2xl">{{ survey.title }}</h1>     
                    <h5 class="text-green-dark font-bold">{{ date }}</h5>
                </div>
                <div class="body w-full mx-auto bg-white mt-6" v-for="question, index in survey.questions" v-if="isVisible(question)">
                    <sup class="text-green-medium uppercase font-bold">domanda {{ index + 1 }}</sup>
                    <h1 class="text-green-dark text-left">{{ question.text }}</h1>
                    <div class="options">
                        <div v-for="option, key in question.options" v-if="options[option.id]" :key="key">
                            <!-- Multiple options -->
                            <label v-if="option.values.length == 0" :id="`l${option.id}`" class="block bg-white text-teal text-left">
                                <span class="text-orange">{{ option.text }}</span>
                                <input v-model="opens[option.id]" v-if="option.open" type="text" class="ml-4 font-bold py-2 px-4 mt-1 text-teal border-none" disabled>
                            </label>
                            <!-- Multiple options with values -->
                            <div v-else class="bg-white text-left mb-2">
                                <span class="lock w-full uppercase text-orange font-bold text-sm">{{ option.text }}</span>
                                <label class="" v-for="value in option.values" class="cursor-pointer inline-block p-0 text-left w-full" v-if="options[value.id]">
                                    {{ value.text }}                         
                                </label>
                            </div>
                        </div>
                    </div>                        
                </div>
            </div>
        </section>
    </vue-html2pdf>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
import checkIco from '@/svg/check.vue'
import { mapGetters, mapState } from 'vuex'

export default {
    components: {checkIco, VueHtml2pdf},
    data() {
        return {        
            survey: {title: ''},
            date: null,
            options: null,
            opens: null,
            scores: null,
            config: {
                margin:       [30,30],
                filename:     '',
                image:        { type: 'jpeg', quality: 0.98 },
                html2canvas:  { scale: 2, letterRendering: true },
                jsPDF:        { unit: 'pt', format: 'letter', orientation: 'portrait' },
                pagebreak:    { mode: ['avoid-all', 'css', 'legacy'] }
            }
        }
    },
    mounted() {        
        if(!this.user.summary) this.$router.push('/dashboard')
        
        this.survey = this.user.summary.survey
        this.date = this.user.summary.entry.created_at
        this.options = this.user.summary.entry.options
        this.opens = this.user.summary.entry.opens
        this.scores = this.user.summary.entry.scores 
        this.config.filename = this.user.summary.survey.title + `(${this.date})`
        this.generateReport()
    },
    methods: {
        isVisible(question) {
            let visible = false
            question.options.map(option => {
                if(this.options[option.id]) visible = true
            })
            return visible
        },
        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        }
    },
    computed: {        
        ...mapState(['user']) 
    }
}
</script>