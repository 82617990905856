<template>
	<div class=" bg-white">
		<div class="container mx-auto px-6 py-4 xl:px-14">
			<div class="flex flex-wrap items-center">
				<div class="w-1/6 sm:w-1/4 text-left">
					<router-link to="/"><img src="@/assets/images/logo.png" class="h-10 w-auto h-auto max-h-10"></router-link>
				</div>
				<div class="w-5/6 sm:w-3/4 text-right">
					<router-link to="/instructions" class="text-sm sm:text-base text-green-dark font-bold px-2 sm:px-4 py-2 mr-2 sm:mr-4"><pencilIco class="w-4 -mt-1 text-white"/> {{ $t("Instructions") }}</router-link>
					<div class="dropdown inline-block relative mr-2 sm:mr-4">
						<button class="text-green-dark font-bold inline-flex items-center focus:outline-none">
							<span class="text-sm sm:text-base mr-1">{{ $t("Language") }}</span>
							<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
						</button>
						<ul class="dropdown-menu absolute hidden text-green-dark pt-1">
							<li class=""><a class="text-sm sm:text-base bg-white hover:bg-orange hover:text-white py-2 px-10 block whitespace-no-wrap" href="#" @click="changeLocale('en')">English</a></li>
							<li class=""><a class="text-sm sm:text-base bg-white hover:bg-orange hover:text-white py-2 px-10 block whitespace-no-wrap" href="#" @click="changeLocale('it')">Italiano</a></li>
							<li class=""><a class="text-sm sm:text-base bg-white hover:bg-orange hover:text-white py-2 px-10 block whitespace-no-wrap" href="#" @click="changeLocale('de')">Deutsch</a></li>
							<li class=""><a class="text-sm sm:text-base bg-white hover:bg-orange hover:text-white py-2 px-10 block whitespace-no-wrap" href="#" @click="changeLocale('fr')">Français</a></li>
							<li class=""><a class="text-sm sm:text-base bg-white hover:bg-orange hover:text-white py-2 px-10 block whitespace-no-wrap" href="#" @click="changeLocale('slo')">Slovenščina</a></li>
							<li class=""><a class="text-sm sm:text-base bg-white hover:bg-orange hover:text-white py-2 px-10 block whitespace-no-wrap" href="#" @click="changeLocale('ru')">Română</a></li>
						</ul>
					</div>
					<router-link v-if="isLogged" to="/surveys" class="bg-orange rounded-full text-white px-4 py-2 mr-2 sm:mr-6"><pencilIco class="w-4 -mt-1 text-white"/> {{ $t("Surveys") }}</router-link>
					<router-link v-if="isLogged" to="/dashboard" class="bg-white border border-orange rounded-full text-orange px-4 py-2 mr-6"><userIco class="w-4 -mt-1 text-orange"/> <span class="hidden sm:inline-block">{{ $t("Dashboard") }}</span></router-link>					
					<router-link v-if="!isLogged" to="/login" class="bg-white border border-orange rounded-full text-orange px-4 py-2 sm:mr-6"><userIco class="w-4 -mt-1 text-orange"/> <span class="hidden sm:inline-block">{{ $t("Login") }}</span></router-link>
					<!-- <router-link to="/" class="font-bold text-teal">ENG</router-link> -->				
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import pencilIco from '@/svg/pencil.vue'
import userIco from '@/svg/user.vue'

export default {
	components: {pencilIco,userIco},
    data() {
        return {}
    },
    computed: {
    	isLogged() {
    		return this.$store.state.token
    	}
    },
    methods: {
    	changeLocale(locale) {
    		this.$i18n.locale = locale
    		// reload survey with current lang if user is logged
    		if(this.$store.state.token) {
    			this.$store.dispatch('loadSurveys')
    			this.$router.push('surveys')    		
    		}
    	}
    }
}
</script>