<template>
    <div class="relative py-24 shadow-inner text-center">             
        <router-link to="/surveys"><xIco class="w-8 text-green-medium absolute top-8 right-8 sm:right-16"/></router-link>

        <div class="heading w-full text-center mx-auto mb-20 px-6">
            <h1 class="text-green-dark text-3xl sm:text-4xl font-bold sm:leading-10">Feedback on the<br> <span class="text-orange">Open Data policy implementation</span> within your organisation</h1>            
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_1.png" class="mx-auto w-1/2">
            </div>
        	<div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">0-25 points</h6>
                <p class="text-green-dark">If your final score is between 0-25 points, the overall level of implementation of the open data policy within the organisation still appears to be poor/limited. There is considerable room for improvement, particularly with regard to the following key features: access to public documents and information, quality and quantity of open data, actual access by citizens. The implementation/follow-up of mandatory obligations is lacking. It is crucial for the organisation to pay more attention to increasing the efficiency of the system as well as to improving the general confidence in its positive impact on fighting corruption. The organisation should also make significant improvements and take concrete actions on the following aspects: allocating skills and resources, establishing a dedicated management structure, and monitoring the level of transparency reached by OD and their use by the public.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">26-45 points</h6>
                <p class="text-green-dark">If your final score is between 26-45 points, the organisation shows a standard level of commitment in implementing the expected open data policy. The policy is implemented in line with the requirements envisaged by law; however, there are some major areas for improvements, particularly those concerning: the presence of a dedicated internal staff (with specific duties and skills on open data) which could strongly contribute to improving access to public data, the bulk/amount of open data released, the periodic monitoring of the implementation process and its actual use by citizens. The organisation should pay great attention to increasing the level of confidence in the impact and value of open data as a means of reducing corruption by public bodies. The improvement actions to be taken may include: the allocation of skills and resources and the assessment of both the transparency level of open data and its actual use by citizens and stakeholders.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_2.png" class="mx-auto w-1/2">
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_3.png" class="mx-auto w-1/2">
            </div>
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-20">
                <h6 class="text-green-medium text-3xl font-bold mb-2">46-70 points</h6>
                <p class="text-green-dark">If your final score is between 46-70 points, this means that the organisation is positively implementing the open data policy. The rules and procedures are properly implemented. Adequate attention is given to the ways of accessing open data as well as their qualitative contents and periodic update. Training is also provided to internal managers and employees on how to make the public body more transparent and accountable. There is a good level of confidence in increasing transparency through open data, representing a meaningful instrument for combating corruption in the public sector. Further progress can be achieved by focusing closely on the assessment of analytics to achieve a more in-depth understanding of the actual level of transparency reached and of data uses by citizens.</p>
            </div>
        </div>

        <div class="flex flex-wrap container px-6 mx-auto items-center mt-16">
            <div class="content w-full md:w-1/2 text-left m-auto lg:pl-32">
                <h6 class="text-green-medium text-3xl font-bold mb-2">71-100 points</h6>
                <p class="text-green-dark">If your final score is between 71-100 points, this means that the organisation is successfully implementing the open data policy and strategy. All implementation measures are put into place in the proper manner. The accessibility and contents of open data, along with their update, are highly prioritised by the organisation. The latter also provides the necessary training to managers and employees working on open data, and the adequate tools to access the data. A systematic review and analysis of the results reflects the organisation’s confidence in terms of transparency and open data as a means of combating corruption in the public sector. Finally, the development of actions aimed at raising the awareness of internal staff and citizens on the impact of open data on public transparency appears particularly consistent.</p>
            </div>
            <div class="content w-full md:w-1/2 text-center m-auto hidden md:block">
                <img src="@/assets/images/feedback_4.png" class="mx-auto w-1/2">
            </div>

            <div class="actions w-full text-center mt-10">
                <router-link to="/surveys" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mr-10 mx-auto">Back to suerveys</router-link> 
                <router-link to="/dashboard" class="w-full block sm:w-auto sm:inline-block relative border border-orange b-3 text-orange px-12 py-2 rounded-full uppercase mt-4 mx-auto">Consult results</router-link> 
            </div>
        </div>
    </div>
</template>
<script>
import xIco from '@/svg/x.vue'

export default {
	components: {xIco},
    data() {
        return {}
    },
    computed: {

    },
    methods: {}
}
</script>