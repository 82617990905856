<template>
	<div class="shadow-inner p-6">
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-8 sm:py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
		  			<h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">Privacy <em class="text-orange not-italic">Policy</em></h1>
		  		</div>
		  		<div class="w-full lg:w-full px-6 text-left">
		  			<h1 class="text-3x font-bold my-4">Obvestilo v skladu s 13. členom Uredbe (EU) št. 2016/679</h1>
		  			<p class="text-teal">		  				
						Dragi sodelujoči,,<br>
						obveščamo vas, da Uredba (EU) št. 679/2016 "Splošna uredba o varstvu podatkov" predvideva varstvo posameznikov pri obdelavi osebnih podatkov. V skladu z navedeno zakonodajo bo ta obravnava temeljila na načelih zakonitosti, pravilnosti in preglednosti, ustreznosti in omejitve, natančnosti in posodabljanju, nepreseganja in odgovornosti. Zato vam posredujemo naslednje informacije:
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">a) Identiteta in kontaktni podatki upravljavca podatkov</h5>
						"Upravljavec podatkov" je Univerza v Torinu - sedež v ulici Verdi 8, 10124 Torino. Centrala +39 011 670.61.11, overjen e-poštni predal (Pec): segreteria.rettore@unito.it, pravni zastopnik: začasni rektor
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">b) Kontaktni podatki osebe, odgovorne za varstvo osebnih podatkov </h5>
						Osebo, odgovorno za varstvo podatkov - OVP, v anglosaški različici Data Protection Officer - DPO, lahko kontaktirate na naslednji e-poštni naslov rpd@unito.it.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">c) Finalità e base giuridica</h5>
						Podatki, ki jih posredujete (osebni podatki), bodo obdelani za izključno znanstvenoraziskovalne namene v okviru evropskega projekta “Whistleblowing Open Data Impact. An Implementation and Impact Assessment” - kratica WOODIe, ki ga financira Sklad za notranjo varnost - Policija (Sporazum o dodelitvi sredstev št. 823799) za dvoletno obdobje 2019–2021. Cilj raziskave je zgraditi sistem za oceno stanja izvajanja in vpliva zakonodaje na zaščito žvižgačev in odprtih podatkov v sedmih evropskih partnerskih državah, da bi poudarili, kako ti ukrepi prispevajo k prepoznavanju in preprečevanju korupcije pri javnih naročilih.
						Projekt vodi Oddelek za pravo Univerze v Torinu. Koordinator projekta je prof. Laura Scomparin (laura.scomparin@unito.it).
						V ta namen bodo udeleženci pozvani, da odgovorijo na vprašanja spletnega vprašalnika. Podatki bodo zbrani v tej prvi raziskavi in ​​nato vsakih šest mesecev.
						Sposobnost sledenja spremembam pri uporabi zgoraj omenjene zakonodaje sčasoma bo raziskovalcem omogočila, da bolje ocenijo njihov vpliv. V ta namen se do vprašalnika dostopa prek registracije. Podatki o registraciji bodo znani samo vam in jih boste uporabili, če se boste vprašalnik občasno ponovili.
						Pravna podlaga obdelave je zakon št. 168/1989 in nadaljnje spremembe. 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">d) Način obdelave</h5>
						Obdelava osebnih podatkov bo potekala avtomatizirano. Upravljavec podatkov bo izvajal ustrezne tehnične in organizacijske ukrepe, kot je psevdonimizacija, katerih namen je učinkovito izvajanje načel varstva podatkov in zaščita pravic posameznikov, na katere se nanašajo osebni podatki. 
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">e) Posredovanje podatkov</h5>
						Sodelovanje v raziskavi je brezplačno in prostovoljno, če udeležba ne povzroči posledic za zainteresirano stran.
                        Sodelovanje zainteresirane strani pri projektu predvideva posredovanje osebnih podatkov, ki jih zahteva registracija.
                        Če podatkov ne posredujete in se ne boste držali raziskovalnega projekta, projekta ne bomo mogli izvesti, kar bo imelo posledice in negativne učinke na znanstveni in tehnološki napredek, družbeno-kulturni razvoj in blaginjo skupnosti.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">f) Prejemniki podatkov</h5>
						Zbrani podatki ne bodo razkriti tretjim osebam. Podatke, zbrane z vprašalnikom, bodo analizirali statistiki delovne skupine. Rezultati se lahko objavijo v zbirni obliki in nikoli v posamezni obliki v znanstvenih revijah.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">g) Prenos podatkov v tretjo državo</h5>
						Zbrani podatki ne bodo preneseni v državo s sedežem zunaj Evropske unije (tako imenovana tretja država). 
					</p>
					<p class="text-teal">	
						<h5 class="font-bold">h) Obdobje hrambe podatkov</h5>
						Osebni podatki se lahko shranjujejo za znanstvene raziskave in/ali statistične namene tudi po preteku obdobja, potrebnega za doseganje namenov, za katere so bili zbrani ali naknadno obdelani, v skladu s čl. 5, § 1 črka e) in 89. členom Uredbe (EU) o obdelavi osebnih podatkov 2016/679 (GDPR) (Podatki se bodo hranili 15 let).
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">i) Pravice o podtkih</h5>
						Kadar koli lahko uveljavljate svoje pravice iz oddelkov 2, 3 in 4 poglavja III Uredbe (EU) št. 679/2016 (npr. pravice do informiranosti in dostopa, popravka in preklica, omejitve in nasprotovanja obdelavi, prenosljivosti osebnih podatkov itd.) S pošiljanjem sporočila z zadevo: Pravice zasebnosti do notranjemu obdelovalcu podatkov direktorju pravnega oddelka: inserire mail, vodji študije laura.scomparin@unito.it in v vednost na rpd@unito.it
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">j) Pritožba</h5>
						Zainteresirana stranka ima pravico vložiti pritožbo pri nadzornem organu in se lahko za zaščito osebnih podatkov obrne na garantni organ: https://www.garanteprivacy.it/.
					</p>
					<p class="text-teal mt-4">	
						<h5 class="font-bold">k) Profiliranje</h5>
						Lastnik ne uporablja avtomatiziranih postopkov za profiliranje v skladu z 22 čl. Uredbe (EU) o obdelavi osebnih podatkov 2016/679 (GDPR)			
		  			</p>
		  		</div>		  		
		  	</div>
		  </div>
		</div>				
	</div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
	components: {arrowRightIco},
}
</script>