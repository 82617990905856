<template>
	<div class="shadow-inner p-6">
		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-8 sm:py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6 mb-12 sm:mb-24">
		  			<h1 class="text-teal text-center text-4xl sm:text-5xl font-bold sm:leading-10">Come funziona lo <em class="text-orange not-italic">strumento</em> di <em class="text-orange not-italic">autovalutazione</em>?</h1>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left">
		  			<p class="text-teal">
		  				Il questionario online è strutturato in due percorsi: uno ha ad oggetto la protezione dei whistleblower e l’altro le politiche in tema di open data. 
		  			</p>
		  			<p class="text-teal mt-4">
						Ogni questionario si compone di due parti:
					</p>
					<p class="text-teal mt-4">
						<em class="text-orange not-italic">-</em> la prima è dedicata alla conoscenza del quadro legislativo in materia, mentre la seconda si concentra sull’implementazione della misura all’interno dell’amministrazione, sulle procedure e sulle azioni adottate, oltre che sui risultati ottenuti. È consigliabile ripetere la compilazione di quest’ultima parte periodicamente poiché fortemente connessa all’attività dell’amministrazione.
					</p>
					<p class="text-teal mt-4">
						<em class="text-orange not-italic">-</em> La seconda parte si concentra su come la misura (open data o protezione whistleblowing) viene implementata all'interno dell'organizzazione, le procedure e le azioni adottate e i risultati ottenuti. Dato che questa parte è fortemente legata alle attività quotidiane dell'organizzazione, dovrebbe essere ripetuta periodicamente per verificare i miglioramenti.
 					
		  			</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left">
		  			<p class="text-teal">
		  				Ogni questionario contiene circa 20 domande e la compilazione richiede approssimativamente 15 minuti.  
		  			<p class="text-teal mt-4">
					</p>
						È possibile:
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> compilare una parte del questionario e riprendere in un secondo momento
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> creare un pdf al termine della compilazione con i relativi risultati
					<p class="text-teal mt-4">
					</p>
						<em class="text-orange not-italic">-</em> verificare l’andamento nel tempo dei punteggi ottenuti 
					<p class="text-teal mt-4">
					</p>
						Lo strumento di autovalutazione prevede, al termine di ogni questionario, un sistema di punteggi e di feedback sotto forma di profili descrittivi volti ad evidenziare quali aree richiedono maggiori attenzioni al fine di migliorare l’impatto delle misure nell’amministrazione.
		  			</p>
		  		</div>
		  		<div class="w-full mt-12 sm:mt-24 text-center">
	                <router-link :to="`/surveys`" class="bg-orange text-white px-4 sm:px-12 py-2 rounded-full uppercase my-10  mx-auto focus:outline-none" href="#">
	                    Vai ai sondaggi <arrowRightIco class="w-6 text-white -mt-1 ml-3"/>
	                </router-link>
	            </div>
		  	</div>
		  </div>
		</div>				
	</div>
</template>
<script>
import arrowRightIco from '@/svg/arrow-right.vue'
export default {
	components: {arrowRightIco},
}
</script>