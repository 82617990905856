<template>
    <div class="flex flex-wrap items-center bg-green-light h-screen">
        <div class="w-full lg:w-5/12 px-6 text-center py-6 lg:h-screen bg-white flex flex-wrap items-start">            
            <h1 v-if="error" class="text-center font-bold text-3xl text-red">{{ $t("Provided id/code are not valid") }}</h1>
            <h1 v-else class="text-center font-bold text-3xl">{{ $t("Loading user profile, please wait") }} <loaderIco class="h-5 w-5"/></h1>
        </div>
        <div class="hidden lg:block lg:w-7/12 text-center">
            <img src="@/assets/images/auth.png" class="w-auto h-auto mx-auto px-20">
        </div>
    </div>
</template>
<script>
import loaderIco from '@/svg/loader.vue'

export default {
    components: { loaderIco },
    data() {
        return {
            error: null
        }
    },
    mounted() {
        this.login()
    },
    methods: {
        login() {
            const payload = {
                id: this.$route.params.id,
                code: this.$route.params.code
            }
            this.disabled = true
            this.axios.post(process.env.VUE_APP_API_URL + '/impersonate', payload)
                .then(response => {
                    this.$store.commit('SET_USER', response.data.data)
                    this.$store.dispatch('loadSurveysAll')
                    this.$router.push('/admin/dashboard')
                })
                .catch(error => {
                    this.disabled = false
                    this.error = error.response.data.message
                })
        }
    },
}
</script>