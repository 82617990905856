<template>
	<div class="body">
		<div class="bg-green-light shadow-inner">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-center">
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6">
		  			<h1 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10">Bine ați venit! Vă invităm să luați parte la <em class="text-orange not-italic">instrumentul de autoevaluare</em> a proiectului Woodie <em class="text-orange not-italic"> privind măsurile anticorupție</em>.</h1>
		  			<p class="lead text-teal font-bold mt-6">Cum gestionează administrațiile publice din întreaga Europă măsurile de combatere a corupției în domeniul achizițiilor publice?</p>
		  			<p class="lead text-teal font-bold mt-2">Cum sunt structurate și implementate politicile de protecție a avertizorilor în interes public și politicile privind datele deschise în cadrul administrațiilor publice?</p>
		  			<p class="text-teal mt-10">Acest instrument de autoevaluare online, realizat de către consorțiul proiectului european Woodie, se concentrează asupra celor două măsuri principale pentru combaterea corupției și protejarea interesului public: protecția avertizorilor în interes public și a datelor deschise.</p>
		  			<button @click="scrollToAction()" class="bg-orange text-white px-12 py-2 rounded-full uppercase mt-10 focus:outline-none">utilizați instrumentul</button>
		  		</div>
		  		<div class="hidden lg:block w-1/2 px-24 text-center">
		  			<img src="@/assets/images/hero.png" class="mx-auto">
		  		</div>
		  	</div>
		  </div>
		</div>

		<div class="bg-green-dark">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6">
		  			<h2 class="text-white text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">Care este</em> scopul instrumentului de autoevaluare Woodie?</h2>
		  			<p class="text-white mt-2">Instrumentul a fost conceput pentru a ajuta administrațiile publice:</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Să-și autoevalueze nivelul de conștientizare cu privire la cadrul legislativ referitor la protecția avertizorilor în interes public și a datelor deschise.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Să afle dacă dispun de sistemul necesar pentru a pune în aplicare pe deplin obligațiile impuse de legislație, pentru a obține rezultate și pentru a măsura progresul realizat în timp în ceea ce privește implementarea.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Servește ca bază pentru îmbunătățirea cunoștințelor în rândul personalului intern.</p>
		  		</div>
		  		<div class="w-full lg:w-1/2 px-6 text-left pt-6 mt-6">
		  			<p class="text-white">Să primească feedback cu privire la domeniile, mecanismele și procedurile care trebuie consolidate pentru a putea îmbunătăți funcționarea și a maximiza impactul celor două măsuri asupra angajaților, părților vizate și cetățenilor.</p>
		  		</div>
		  	</div>
		  </div>
		</div>

		<div class="bg-green-light">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start">
		  		<div class="w-full px-6 text-left pt-6">
		  			<h2 class="text-teal text-2xl sm:text-4xl font-bold sm:leading-10"><em class="text-orange not-italic">Cine</em> poate completa instrumentul de autoevaluare Woodie?</h2>
		  		</div>
		  		<div class="w-full px-6 text-left mt-6">
		  			<p class="text-teal">Instrumentul se adresează administrațiilor publice din țările europene, indiferent de dimensiunea acestora. Acesta trebuie completat de către angajații publici cărora li se atribuie sarcini și responsabilități în domeniile în care sunt semnalate nereguli (avertizori în interes public) și/sau date deschise. Chestionarul privind avertizorii în interes public și chestionarul privind datele deschise pot fi completate de același angajat sau de doi sau mai mulți angajați.</p>
		  		</div>
		  	</div>
		  </div>
		</div>

		<div class="bg-green-medium" id="cta">
		  <div class="container mx-auto xl:px-8 py-16">
		  	<div class="flex flex-wrap items-start justify-center">
		  		<div class="w-full md:w-2/3 px-6 text-left pt-6">
		  			<h2 class="text-white text-center text-3xl sm:text-5xl font-bold sm:leading-10"><em class="text-green-dark not-italic">Începeți</em> să utilizați instrumentul</h2>
		  			<p class="text-white mt-6 text-center">Dacă încă nu aveți un cont, puteți continua înregistrarea. Dacă aveți deja unul, trebuie doar să faceți clic pe Logare și să începeți să utilizați instrumentul.</p>
		  			<div class="mt-4 text-center">
		  				<router-link to="/register" class="block w-full mx-auto bg-transparent border text-white text-sm font-bold px-12 py-3 rounded-full uppercase mt-6 md:mt-10 md:inline-block md:w-auto md:mr-10">Inregistrare</router-link>
		  				<router-link to="/login" class="block w-full mx-auto bg-white border border-white text-sm text-green-dark font-bold px-12 py-3 rounded-full uppercase mt-4 md:mt-10 md:inline-block md:w-auto">Logare</router-link>
		  			</div>
		  		</div>
		  	</div>
		  </div>
		</div>
	</div>
</template>
<script>
export default {
  methods: {
  	scrollToAction() {
 		document.getElementById('cta').scrollIntoView({behavior: 'smooth'});	
  	}
  },
}
</script>